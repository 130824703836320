/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./home.scss";
import withRouter from "@/app/comRouter/withRouter";
import Header from "@/layouts/comHeader/Header";
import SlideImage from "@/components/comSlideImage/SlideImage";
import Icon from "@/components/comIcon/Icon";
import { NavLink } from "react-router-dom";
import Func from "@/app/comFunc/Func";
import Slider from "react-slick";
import Clock from "@/components/comClock/Clock";
import Partner from "../comAuth/Partner";

const gameTabSlideSetting = {
    dots: false,
    infinite: false,
    speed: 100,
    slidesToShow: 5,
    slidesToScroll: 5,
    adaptiveHeight: false,
    margin: 10,
    arrows: true,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 6,
                slidesToScroll: 6,
            },
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
            },
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            },
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            },
        },
    ],
};
export const gameViet = [
    {
        name: "Xổ số Miền Bắc",
        meta: "Mở sau 5 phút",
        code: null,
        image: "/images/games/SaiGon5D5Phut.png",
        show: true,
        enabled: false,
        linkResult: null,
        linkTrend: null,
        linkBet: null,
    },
    {
        name: "Xổ số Miền Trung",
        meta: "144 số phát hành mỗi ngày",
        code: null,
        image: "/images/games/VietNam6D10Phut.png",
        show: true,
        enabled: false,
        linkResult: null,
        linkTrend: null,
        linkBet: null,
    },
    {
        name: "Xổ số Miền Nam",
        meta: "288 số phát hành mỗi ngày",
        code: null,
        image: "/images/games/VietNam6D5Phut.png",
        show: true,
        enabled: false,
        linkResult: null,
        linkTrend: null,
        linkBet: null,
    },
    // {
    //     name: "Sài Gòn 5D 5 phút",
    //     meta: "Mở sau 5 phút",
    //     code: null,
    //     image: "/images/games/SaiGon5D5Phut.png",
    //     show: false,
    //     enabled: false,
    //     linkResult: null,
    //     linkTrend: null,
    //     linkBet: null,
    // },
    // {
    //     name: "Sài Gòn 5D 1 phút",
    //     meta: "Mở sau 1 phút",
    //     code: null,
    //     image: "/images/games/SaiGon5D1Phut.png",
    //     show: false,
    //     enabled: false,
    //     linkResult: null,
    //     linkTrend: null,
    //     linkBet: null,
    // },
    // {
    //     name: "Việt Nam 6D 10 phút",
    //     meta: "144 số phát hành mỗi ngày",
    //     code: null,
    //     image: "/images/games/VietNam6D10Phut.png",
    //     show: false,
    //     enabled: false,
    //     linkResult: null,
    //     linkTrend: null,
    //     linkBet: null,
    // },
    // {
    //     name: "Việt Nam 6D 5 phút",
    //     meta: "288 số phát hành mỗi ngày",
    //     code: null,
    //     image: "/images/games/VietNam6D5Phut.png",
    //     show: false,
    //     enabled: false,
    //     linkResult: null,
    //     linkTrend: null,
    //     linkBet: null,
    // },
    // {
    //     name: "Việt Nam 6D 3 phút",
    //     meta: "480 số phát hành mỗi ngày",
    //     code: null,
    //     image: "/images/games/VietNam6D3Phut.png",
    //     show: false,
    //     enabled: false,
    //     linkResult: null,
    //     linkTrend: null,
    //     linkBet: null,
    // },
    // {
    //     name: "Việt Nam 6D 1 phút",
    //     meta: "1440 số phát hành mỗi ngày",
    //     code: null,
    //     image: "/images/games/VietNam6D1Phut.png",
    //     show: false,
    //     enabled: false,
    //     linkResult: null,
    //     linkTrend: null,
    //     linkBet: null,
    // },
    // {
    //     name: "Việt Nam 5D 10 phút",
    //     meta: "144 số phát hành mỗi ngày",
    //     code: null,
    //     image: "/images/games/VietNam5D10Phut.png",
    //     show: false,
    //     enabled: false,
    //     linkResult: null,
    //     linkTrend: null,
    //     linkBet: null,
    // },
    // {
    //     name: "Việt Nam 5D 1 phút",
    //     meta: "1440 số phát hành mỗi ngày",
    //     code: null,
    //     image: "/images/games/VietNam5D1Phut.png",
    //     show: false,
    //     enabled: false,
    //     linkResult: null,
    //     linkTrend: null,
    //     linkBet: null,
    // },
    // {
    //     name: "Việt Nam 5D 3 phút",
    //     meta: "480 số phát hành mỗi ngày",
    //     code: null,
    //     image: "/images/games/VietNam5D3Phut.png",
    //     show: false,
    //     enabled: false,
    //     linkResult: null,
    //     linkTrend: null,
    //     linkBet: null,
    // },
    // {
    //     name: "Việt Nam 5D 5 phút",
    //     meta: "288 số phát hành mỗi ngày",
    //     code: null,
    //     image: "/images/games/VietNam5D5Phut.png",
    //     show: false,
    //     enabled: false,
    //     linkResult: null,
    //     linkTrend: null,
    //     linkBet: null,
    // },
];
export const gameVui = [
    {
        name: "Đoán Màu 1 phút",
        meta: "1440 số phát hành mỗi ngày",
        code: null,
        image: "/images/games/DoanMau1Phut.png",
        show: false,
        enabled: false,
        linkResult: null,
        linkTrend: null,
        linkBet: null,
    },
    {
        name: "Đoán Màu 5 phút",
        meta: "288 số phát hành mỗi ngày",
        code: null,
        image: "/images/games/DoanMau5Phut.png",
        show: false,
        enabled: false,
        linkResult: null,
        linkTrend: null,
        linkBet: null,
    },
    {
        name: "Đoán Màu 3 phút",
        meta: "Đoán Màu 3 phút",
        code: null,
        image: "/images/games/DoanMau3Phut.png",
        show: false,
        enabled: false,
        linkResult: null,
        linkTrend: null,
        linkBet: null,
    },
    {
        name: "Bầu Cua 3 phút",
        meta: "Mở sau 3 phút",
        code: null,
        image: "/images/games/BauCua3Phut.png",
        show: false,
        enabled: false,
        linkResult: null,
        linkTrend: null,
        linkBet: null,
    },
    {
        name: "Bầu Cua 1 phút",
        meta: "Mở sau 1 phút",
        code: null,
        image: "/images/games/BauCua1Phut.png",
        show: false,
        enabled: false,
        linkResult: null,
        linkTrend: null,
        linkBet: null,
    },
    {
        name: "Xóc Đĩa 3 phút",
        meta: "Mở sau 3 phút",
        code: null,
        image: "/images/games/XocDia3Phut.png",
        show: false,
        enabled: false,
        linkResult: null,
        linkTrend: null,
        linkBet: null,
    },
    {
        name: "Xóc Đĩa 1 phút",
        meta: "Mở sau 1 phút",
        code: null,
        image: "/images/games/XocDia1Phut.png",
        show: false,
        enabled: false,
        linkResult: null,
        linkTrend: null,
        linkBet: null,
    },
];
export const game5D = [
    {
        name: "5D 10 phút",
        meta: "Mở sau 10 phút",
        code: null,
        image: "/images/games/5D10Phut.png",
        show: false,
        enabled: false,
        linkResult: null,
        linkTrend: null,
        linkBet: null,
    },
    {
        name: "5D 5 phút",
        meta: "Mở sau 5 phút",
        code: null,
        image: "/images/games/5D5Phut.png",
        show: false,
        enabled: false,
        linkResult: null,
        linkTrend: null,
        linkBet: null,
    },
    {
        name: "5D 1 phút",
        meta: "Mở sau 1 phút",
        code: null,
        image: "/images/games/5D1Phut.png",
        show: false,
        enabled: false,
        linkResult: null,
        linkTrend: null,
        linkBet: null,
    },
    {
        name: "5D 3 phút",
        meta: "Mở sau 3 phút",
        code: null,
        image: "/images/games/5D3Phut.png",
        show: false,
        enabled: false,
        linkResult: null,
        linkTrend: null,
        linkBet: "game.GameFiveBallThreeMin",
    },
];
export const game11x5 = [
    {
        name: "11x5 3 phút",
        meta: "Mở sau 3 phút",
        code: null,
        image: "/images/games/3Phut11x5.png",
        show: false,
        enabled: false,
        linkResult: null,
        linkTrend: null,
        linkBet: null,
    },
    {
        name: "11x5 1 phút",
        meta: "Mở sau 1 phút",
        code: null,
        image: "/images/games/1Phut11x5.png",
        show: false,
        enabled: false,
        linkResult: null,
        linkTrend: null,
        linkBet: null,
    },
    {
        name: "11x5 5 phút",
        meta: "Mở sau 5 phút",
        code: null,
        image: "/images/games/5Phut11x5.png",
        show: false,
        enabled: false,
        linkResult: null,
        linkTrend: null,
        linkBet: null,
    },
    {
        name: "11x5 10 phút",
        meta: "Mở sau 10 phút",
        code: null,
        image: "/images/games/10Phut11x5.png",
        show: false,
        enabled: false,
        linkResult: null,
        linkTrend: null,
        linkBet: null,
    },
];
export const gameNhanh3 = [
    {
        name: "Xổ số 10phút",
        meta: "Mở sau 10phút",
        code: "game_three_dice_ten_min",
        image: "/images/games/10PhutNhanh3.png",
        show: false,
        enabled: false,
        linkResult: "result.GameThreeDiceTenMin",
        linkTrend: null,
        linkBet: "game.GameThreeDiceTenMin",
    },
    {
        name: "Xổ số 5phút",
        meta: "Mở sau 5 phút",
        code: "game_three_dice_five_min",
        image: "/images/games/5PhutNhanh3.png",
        show: true,
        enabled: true,
        linkResult: "result.GameThreeDiceFiveMin",
        linkTrend: null,
        linkBet: "game.GameThreeDiceFiveMin",
    },
    {
        name: "Xổ số 3phút",
        meta: "Mở sau 3 phút",
        code: "game_three_dice_three_min",
        image: "/images/games/3PhutNhanh3.png",
        show: false,
        enabled: false,
        linkResult: "result.GameThreeDiceThreeMin",
        linkTrend: null,
        linkBet: "game.GameThreeDiceThreeMin",
    },
    {
        name: "Xổ số 1phút",
        meta: "Mở sau 1 phút",
        code: "game_three_dice_one_min",
        image: "/images/games/1PhutNhanh3.png",
        show: false,
        enabled: false,
        linkResult: "result.GameThreeDiceOneMin",
        linkTrend: null,
        linkBet: "game.GameThreeDiceOneMin",
    },
];
export const gameCuocDua = [
    {
        name: "Đua xe 10 phút",
        meta: "Mở sau 10 phút",
        code: null,
        image: "/images/games/DuaXe10Phut.png",
        show: false,
        enabled: false,
        linkResult: null,
        linkTrend: null,
        linkBet: null,
    },
    {
        name: "Đua xe 5 phút",
        meta: "Mở sau 5 phút",
        code: null,
        image: "/images/games/DuaXe5Phut.png",
        show: false,
        enabled: false,
        linkResult: null,
        linkTrend: null,
        linkBet: null,
    },
    {
        name: "Đua xe 3 phút",
        meta: "Mở sau 3 phút",
        code: null,
        image: "/images/games/DuaXe3Phut.png",
        show: false,
        enabled: false,
        linkResult: null,
        linkTrend: null,
        linkBet: null,
    },
    {
        name: "Đua xe 1 phút",
        meta: "Mở sau 1 phút",
        code: null,
        image: "/images/games/DuaXe1Phut.png",
        show: false,
        enabled: false,
        linkResult: null,
        linkTrend: null,
        linkBet: null,
    },
    {
        name: "Tàu bay 10 phút",
        meta: "Mở sau 10 phút",
        code: null,
        image: "/images/games/TauBay10Phut.png",
        show: false,
        enabled: false,
        linkResult: null,
        linkTrend: null,
        linkBet: null,
    },
    {
        name: "Tàu bay 5 phút",
        meta: "Mở sau 5 phút",
        code: null,
        image: "/images/games/TauBay5Phut.png",
        show: false,
        enabled: false,
        linkResult: null,
        linkTrend: null,
        linkBet: null,
    },
    {
        name: "Tàu bay 3 phút",
        meta: "Mở sau 3 phút",
        code: null,
        image: "/images/games/TauBay3Phut.png",
        show: false,
        enabled: false,
        linkResult: null,
        linkTrend: null,
        linkBet: null,
    },
    {
        name: "Tàu bay 1 phút",
        meta: "Mở sau 1 phút",
        code: null,
        image: "/images/games/TauBay1Phut.png",
        show: false,
        enabled: false,
        linkResult: null,
        linkTrend: null,
        linkBet: null,
    },
];
export const gameKhac = [
    {
        name: "Cai3D 5 phút",
        meta: "Mở sau 5 phút",
        code: null,
        image: "/images/games/Cai3D5Phut.png",
        show: false,
        enabled: false,
        linkResult: null,
        linkTrend: null,
        linkBet: null,
    },
    {
        name: "Cai3D 3 phút",
        meta: "Mở sau 3 phút",
        code: null,
        image: "/images/games/Cai3D3Phut.png",
        show: false,
        enabled: false,
        linkResult: null,
        linkTrend: null,
        linkBet: null,
    },
    {
        name: "Cai3D 10 phút",
        meta: "Mở sau 10 phút",
        code: null,
        image: "/images/games/Cai3D10Phut.png",
        show: false,
        enabled: false,
        linkResult: null,
        linkTrend: null,
        linkBet: null,
    },
    {
        name: "Cai3D 1 phút",
        meta: "Mở sau 1 phút",
        code: null,
        image: "/images/games/Cai3D1Phut.png",
        show: false,
        enabled: false,
        linkResult: null,
        linkTrend: null,
        linkBet: null,
    },
    {
        name: "Hạnh phúc 10 3 phút",
        meta: "Mở sau 3 phút",
        code: null,
        image: "/images/games/3PhutHanhPhuc10.png",
        show: false,
        enabled: false,
        linkResult: null,
        linkTrend: null,
        linkBet: null,
    },
    {
        name: "Hạnh phúc 10 5 phút",
        meta: "Mở sau 5 phút",
        code: null,
        image: "/images/games/5PhutHanhPhuc10.png",
        show: false,
        enabled: false,
        linkResult: null,
        linkTrend: null,
        linkBet: null,
    },
    {
        name: "Hạnh phúc 10 10 phút",
        meta: "Mở sau 10 phút",
        code: null,
        image: "/images/games/10PhutHanhPhuc10.png",
        show: false,
        enabled: false,
        linkResult: null,
        linkTrend: null,
        linkBet: null,
    },
    {
        name: "Hạnh phúc 10 1 phút",
        meta: "Mở sau 1 phút",
        code: null,
        image: "/images/games/1PhutHanhPhuc10.png",
        show: false,
        enabled: false,
        linkResult: null,
        linkTrend: null,
        linkBet: null,
    },
    {
        name: "Fucai 3D",
        meta: "Mở hàng ngày",
        code: null,
        image: "/images/games/Fucai3D.png",
        show: false,
        enabled: false,
        linkResult: null,
        linkTrend: null,
        linkBet: null,
    },
    {
        name: "Chọn3 3 phút",
        meta: "Mở hàng ngày",
        code: null,
        image: "/images/games/3PhutChon3.png",
        show: false,
        enabled: false,
        linkResult: null,
        linkTrend: null,
        linkBet: null,
    },
    {
        name: "Chọn3 10 phút",
        meta: "Mở hàng ngày",
        code: null,
        image: "/images/games/10PhutChon3.png",
        show: false,
        enabled: false,
        linkResult: null,
        linkTrend: null,
        linkBet: null,
    },
    {
        name: "Chọn3 5 phút",
        meta: "Mở hàng ngày",
        code: null,
        image: "/images/games/5PhutChon3.png",
        show: false,
        enabled: false,
        linkResult: null,
        linkTrend: null,
        linkBet: null,
    },
];
export const gameMark6 = [
    {
        name: "Mark6 5 phút",
        meta: "Mở sau 5 phút",
        code: null,
        image: "/images/games/5PhutMark6.png",
        show: false,
        enabled: false,
        linkResult: null,
        linkTrend: null,
        linkBet: null,
    },
    {
        name: "Mark6 10 phút",
        meta: "Mở sau 10 phút",
        code: null,
        image: "/images/games/10PhutMark6.png",
        show: false,
        enabled: false,
        linkResult: null,
        linkTrend: null,
        linkBet: null,
    },
    {
        name: "Mark6 3 phút",
        meta: "Mở sau 3 phút",
        code: null,
        image: "/images/games/3PhutMark6.png",
        show: false,
        enabled: false,
        linkResult: null,
        linkTrend: null,
        linkBet: null,
    },
    {
        name: "Mark6 1 phút",
        meta: "Mở sau 1 phút",
        code: null,
        image: "/images/games/1PhutMark6.png",
        show: false,
        enabled: false,
        linkResult: null,
        linkTrend: null,
        linkBet: null,
    },
];
export const gamePC28 = [
    {
        name: "28 10 phút",
        meta: "Mở sau 10 phút",
        code: null,
        image: "/images/games/10Phut28.png",
        show: false,
        enabled: false,
        linkResult: null,
        linkTrend: null,
        linkBet: null,
    },
    {
        name: "28 3 phút",
        meta: "Mở sau 3 phút",
        code: null,
        image: "/images/games/3Phut28.png",
        show: false,
        enabled: false,
        linkResult: null,
        linkTrend: null,
        linkBet: null,
    },
    {
        name: "28 1 phút",
        meta: "Mở sau 1 phút",
        code: null,
        image: "/images/games/1Phut28.png",
        show: false,
        enabled: false,
        linkResult: null,
        linkTrend: null,
        linkBet: null,
    },
    {
        name: "28 5 phút",
        meta: "Mở sau 5 phút",
        code: null,
        image: "/images/games/5Phut28.png",
        show: false,
        enabled: false,
        linkResult: null,
        linkTrend: null,
        linkBet: null,
    },
];
export const gameTatCa = [
    // ...game5D,
    ...gameNhanh3,
    // {
    //     name: "Mới May Mắn 28",
    //     meta: "Mở sau 5 phút",
    //     code: null,
    //     image: "/images/games/MoiMayMan28.png",
    //     show: false,
    //     enabled: false,
    //     linkResult: null,
    //     linkTrend: null,
    //     linkBet: null,
    // },
    // {
    //     name: "Jackpot xổ số",
    //     meta: "12 số phát hành mỗi ngày",
    //     code: null,
    //     image: "/images/games/JackpotXoSo.png",
    //     show: false,
    //     enabled: false,
    //     linkResult: null,
    //     linkTrend: null,
    //     linkBet: null,
    // },
    ...gameViet,
    // ...gameVui,
    // ...game11x5,
    // ...gameCuocDua,
    // ...gameKhac,
    // ...gameMark6,
    // ...gamePC28,
    // ...gamePC28,
];
export const gameTabData = [
    {
        name: "Tất cả",
        key: "gameTatCa",
        default: true,
        items: gameTatCa,
    },
    // {
    //     name: "5D",
    //     key: "game5D",
    //     default: false,
    //     items: game5D,
    // },
    {
        name: "Xổ số nhanh",
        key: "gameNhanh3",
        default: false,
        items: gameNhanh3,
    },
    {
        name: "Game Việt",
        key: "gameViet",
        default: false,
        items: gameViet,
        // items: null,
    },
    // {
    //     name: "SEA",
    //     key: "gameSea",
    //     default: false,
    //     items: null,
    // },
    // {
    //     name: "11x5",
    //     key: "game11x5",
    //     default: false,
    //     // items: game11x5,
    //     items: null,
    // },
    // {
    //     name: "Mục lục",
    //     key: "gameMucLuc",
    //     default: false,
    //     items: null,
    // },
    // {
    //     name: "Cuộc đua",
    //     key: "gameCuocDua",
    //     default: false,
    //     // items: gameCuocDua,
    //     items: null,
    // },
    // {
    //     name: "Khác",
    //     key: "gameKhac",
    //     default: false,
    //     // items: gameKhac,
    //     items: null,
    // },
    // {
    //     name: "Mark 6",
    //     key: "gameMark6",
    //     default: false,
    //     // items: gameMark6,
    //     items: null,
    // },
    // {
    //     name: "PC28",
    //     key: "gamePC28",
    //     default: false,
    //     // items: gamePC28,
    //     items: null,
    // },
];
export const PageSlide = () => {
    return (
        <div className="page__slide">
            <div className="page__slide-bg"></div>
            <div className="container">
                <SlideImage
                    array={[
                        {
                            url: "/images/photo_7_2023-11-23_19-45-31.jpg",
                        },
                        {
                            url: "/images/photo_6_2023-11-23_19-45-31.jpg",
                        },
                        {
                            url: "/images/photo_5_2023-11-23_19-45-31.jpg",
                        },
                        {
                            url: "/images/photo_4_2023-11-23_19-45-31.jpg",
                        },
                        {
                            url: "/images/photo_3_2023-11-23_19-45-31.jpg",
                        },
                        {
                            url: "/images/photo_2_2023-11-23_19-45-31.jpg",
                        },
                        {
                            url: "/images/photo_1_2023-11-23_19-45-31.jpg",
                        },
                        {
                            url: "/images/photo_13_2023-11-23_19-45-31.jpg",
                        },
                        {
                            url: "/images/photo_12_2023-11-23_19-45-31.jpg",
                        },
                        {
                            url: "/images/photo_11_2023-11-23_19-45-31.jpg",
                        },
                        {
                            url: "/images/photo_10_2023-11-23_19-45-31.jpg",
                        },
                    ]}
                    className="page__slide-content"
                ></SlideImage>
            </div>
        </div>
    );
};
export const PageWelcome = () => {
    return (
        <div className="page__welcome">
            <h1 style={{ textAlign: "center" }}>
                Chào mừng bạn đến với <br />
                <strong style={{ fontWeight: "600" }}>
                    Marina Bay Sands | Singapore
                </strong>
            </h1>
            <Clock></Clock>
        </div>
    );
};
class Home extends React.Component {
    constructor(props) {
        super(props);
        window.Home = this;
        this.state = {
            gameTabActive: null,
            gameTabContent: gameTatCa,
        };
    }
    _isMounted = false;
    componentDidMount = () => {
        this._isMounted = true;
    };
    componentWillUnmount() {
        this._isMounted = false;
    }
    handleTabGame = (key, data) => {
        this._isMounted &&
            this.setState({
                gameTabActive: key,
                gameTabContent: data ? data : null,
            });
    };
    render() {
        return (
            <React.Fragment>
                <Header></Header>
                <div className="home">
                    <PageSlide></PageSlide>
                    <div className="home__middle">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <PageWelcome></PageWelcome>
                                    <div className="home__notification">
                                        <Icon
                                            type="fas"
                                            className="fa-volume-high"
                                        ></Icon>
                                        <span>Công bố:</span>
                                        <div>
                                            <p>
                                                <span
                                                    dangerouslySetInnerHTML={{
                                                        __html: "Chào mừng bạn đến Marina Bay Sands | Singapore! &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp Chào mừng bạn đến Marina Bay Sands | Singapore! &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp Chào mừng bạn đến Marina Bay Sands | Singapore! &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp Chào mừng bạn đến Marina Bay Sands | Singapore! &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp Chào mừng bạn đến Marina Bay Sands | Singapore! &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp Chào mừng bạn đến Marina Bay Sands | Singapore! &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp Chào mừng bạn đến Marina Bay Sands | Singapore! &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp Chào mừng bạn đến Marina Bay Sands | Singapore! &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp",
                                                    }}
                                                ></span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="home__tab">
                                        {/* <div
                                            // to={Func.getRoute(
                                            //     "account.recharge"
                                            // )}
                                            className="home__tab-item"
                                            style={{ position: "relative" }}
                                        >
                                            <Icon
                                                type="far"
                                                className="fa-envelope-open-dollar"
                                            ></Icon>
                                            <span>Nạp tiền</span>
                                            <div
                                                data-id="Te2lUyFlsS"
                                                className="livechat_button"
                                            ></div>
                                        </div> */}
                                        <div
                                            className="home__tab-item"
                                            style={{ position: "relative" }}
                                            onClick={() =>
                                                document
                                                    .querySelector("#cskh a")
                                                    .click()
                                            }
                                        >
                                            <Icon
                                                type="far"
                                                className="fa-envelope-open-dollar"
                                            ></Icon>
                                            <span>Nạp tiền</span>
                                        </div>
                                        <NavLink
                                            to={Func.getRoute(
                                                "account.withdraw"
                                            )}
                                            className="home__tab-item"
                                        >
                                            <Icon
                                                type="far"
                                                className="fa-money-from-bracket"
                                            ></Icon>
                                            <span>Rút tiền</span>
                                        </NavLink>
                                        <NavLink
                                            to={Func.getRoute("home")}
                                            className="home__tab-item home__tab-item-active"
                                        >
                                            <Icon
                                                type="far"
                                                className="fa-dice"
                                            ></Icon>
                                            <span>Trò chơi</span>
                                        </NavLink>
                                        <NavLink
                                            to={Func.getRoute("home")}
                                            onClick={(event) =>
                                                Func.alert(
                                                    "Trò chơi hiện đang bảo trì !"
                                                )
                                            }
                                            className="home__tab-item"
                                        >
                                            <Icon
                                                type="fab"
                                                className="fa-d-and-d"
                                            ></Icon>
                                            <span>Rồng</span>
                                        </NavLink>
                                    </div>
                                    <div className="home__winner">
                                        <div>
                                            <p>
                                                <span>
                                                    &nbsp; &nbsp; &nbsp; &nbsp;
                                                    &nbsp;
                                                </span>
                                                <span>【1 phút】aeio***p2</span>
                                                <span>
                                                    &nbsp; &nbsp; &nbsp; &nbsp;
                                                    &nbsp;
                                                </span>
                                                <span>
                                                    2xx00<span> USD</span>
                                                </span>
                                                <span>
                                                    &nbsp; &nbsp; &nbsp; &nbsp;
                                                    &nbsp;
                                                </span>
                                                <span>【5 phút】tere**pcf</span>
                                                <span>
                                                    &nbsp; &nbsp; &nbsp; &nbsp;
                                                    &nbsp;
                                                </span>
                                                <span>
                                                    1xx30<span> USD</span>
                                                </span>
                                                <span>
                                                    &nbsp; &nbsp; &nbsp; &nbsp;
                                                    &nbsp;
                                                </span>
                                                <span>【1 phút】yui****6g</span>
                                                <span>
                                                    &nbsp; &nbsp; &nbsp; &nbsp;
                                                    &nbsp;
                                                </span>
                                                <span>
                                                    8x80<span> USD</span>
                                                </span>
                                                <span>
                                                    &nbsp; &nbsp; &nbsp; &nbsp;
                                                    &nbsp;
                                                </span>
                                                <span>【1 phút】qwer**fux</span>
                                                <span>
                                                    &nbsp; &nbsp; &nbsp; &nbsp;
                                                    &nbsp;
                                                </span>
                                                <span>
                                                    2xx29<span> USD</span>
                                                </span>
                                                <span>
                                                    &nbsp; &nbsp; &nbsp; &nbsp;
                                                    &nbsp;
                                                </span>
                                                <span>【5 phút】dcdc**vc9</span>
                                                <span>
                                                    &nbsp; &nbsp; &nbsp; &nbsp;
                                                    &nbsp;
                                                </span>
                                                <span>
                                                    2xx99<span> USD</span>
                                                </span>
                                                <span>
                                                    &nbsp; &nbsp; &nbsp; &nbsp;
                                                    &nbsp;
                                                </span>
                                                <span>
                                                    【5 phút】shen****i6
                                                </span>
                                                <span>
                                                    &nbsp; &nbsp; &nbsp; &nbsp;
                                                    &nbsp;
                                                </span>
                                                <span>
                                                    5xx58<span> USD</span>
                                                </span>
                                                <span>
                                                    &nbsp; &nbsp; &nbsp; &nbsp;
                                                    &nbsp;
                                                </span>
                                                <span>
                                                    【1 phút】shab****9le
                                                </span>
                                                <span>
                                                    &nbsp; &nbsp; &nbsp; &nbsp;
                                                    &nbsp;
                                                </span>
                                                <span>
                                                    7xx82<span> USD</span>
                                                </span>
                                                <span>
                                                    &nbsp; &nbsp; &nbsp; &nbsp;
                                                    &nbsp;
                                                </span>
                                                <span>【5 phút】zhu***c0</span>
                                                <span>
                                                    &nbsp; &nbsp; &nbsp; &nbsp;
                                                    &nbsp;
                                                </span>
                                                <span>
                                                    6xx65<span> USD</span>
                                                </span>
                                                <span>
                                                    &nbsp; &nbsp; &nbsp; &nbsp;
                                                    &nbsp;
                                                </span>
                                                <span>
                                                    【1 phút】jimo***sxi
                                                </span>
                                                <span>
                                                    &nbsp; &nbsp; &nbsp; &nbsp;
                                                    &nbsp;
                                                </span>
                                                <span>
                                                    9xx35<span> USD</span>
                                                </span>
                                                <span>
                                                    &nbsp; &nbsp; &nbsp; &nbsp;
                                                    &nbsp;
                                                </span>
                                                <span>
                                                    【5 phút】zee****88b
                                                </span>
                                                <span>
                                                    &nbsp; &nbsp; &nbsp; &nbsp;
                                                    &nbsp;
                                                </span>
                                                <span>
                                                    1xx91<span> USD</span>
                                                </span>
                                                <span>
                                                    &nbsp; &nbsp; &nbsp; &nbsp;
                                                    &nbsp;
                                                </span>
                                                <span>【1 phút】demo**dy</span>
                                                <span>
                                                    &nbsp; &nbsp; &nbsp; &nbsp;
                                                    &nbsp;
                                                </span>
                                                <span>
                                                    4xx23<span> USD</span>
                                                </span>
                                                <span>
                                                    &nbsp; &nbsp; &nbsp; &nbsp;
                                                    &nbsp;
                                                </span>
                                                <span>【5 phút】wlgc**82</span>
                                                <span>
                                                    &nbsp; &nbsp; &nbsp; &nbsp;
                                                    &nbsp;
                                                </span>
                                                <span>
                                                    9xx14<span> USD</span>
                                                </span>
                                                <span>
                                                    &nbsp; &nbsp; &nbsp; &nbsp;
                                                    &nbsp;
                                                </span>
                                                <span>
                                                    【1 phút】zent***4j5
                                                </span>
                                                <span>
                                                    &nbsp; &nbsp; &nbsp; &nbsp;
                                                    &nbsp;
                                                </span>
                                                <span>
                                                    4xx84<span> USD</span>
                                                </span>
                                                <span>
                                                    &nbsp; &nbsp; &nbsp; &nbsp;
                                                    &nbsp;
                                                </span>
                                                <span>
                                                    【5 phút】zant****0jz
                                                </span>
                                                <span>
                                                    &nbsp; &nbsp; &nbsp; &nbsp;
                                                    &nbsp;
                                                </span>
                                                <span>
                                                    8xx97<span> USD</span>
                                                </span>
                                                <span>
                                                    &nbsp; &nbsp; &nbsp; &nbsp;
                                                    &nbsp;
                                                </span>
                                                <span>【1 phút】zhai**39l</span>
                                                <span>
                                                    &nbsp; &nbsp; &nbsp; &nbsp;
                                                    &nbsp;
                                                </span>
                                                <span>
                                                    7xx26<span> USD</span>
                                                </span>
                                                <span>
                                                    &nbsp; &nbsp; &nbsp; &nbsp;
                                                    &nbsp;
                                                </span>
                                                <span>【5 phút】zee**ak</span>
                                                <span>
                                                    &nbsp; &nbsp; &nbsp; &nbsp;
                                                    &nbsp;
                                                </span>
                                                <span>
                                                    2xx98<span> USD</span>
                                                </span>
                                                <span>
                                                    &nbsp; &nbsp; &nbsp; &nbsp;
                                                    &nbsp;
                                                </span>
                                                <span>【3 phút】qpal**6wr</span>
                                                <span>
                                                    &nbsp; &nbsp; &nbsp; &nbsp;
                                                    &nbsp;
                                                </span>
                                                <span>
                                                    1xx18<span> USD</span>
                                                </span>
                                                <span>
                                                    &nbsp; &nbsp; &nbsp; &nbsp;
                                                    &nbsp;
                                                </span>
                                                <span>【1 phút】5sin**ke</span>
                                                <span>
                                                    &nbsp; &nbsp; &nbsp; &nbsp;
                                                    &nbsp;
                                                </span>
                                                <span>
                                                    8xx30<span> USD</span>
                                                </span>
                                                <span>
                                                    &nbsp; &nbsp; &nbsp; &nbsp;
                                                    &nbsp;
                                                </span>
                                                <span>
                                                    【5 phút】aini****3c
                                                </span>
                                                <span>
                                                    &nbsp; &nbsp; &nbsp; &nbsp;
                                                    &nbsp;
                                                </span>
                                                <span>
                                                    5xx35<span> USD</span>
                                                </span>
                                                <span>
                                                    &nbsp; &nbsp; &nbsp; &nbsp;
                                                    &nbsp;
                                                </span>
                                                <span>
                                                    【1 phút】kunl****pxa
                                                </span>
                                                <span>
                                                    &nbsp; &nbsp; &nbsp; &nbsp;
                                                    &nbsp;
                                                </span>
                                                <span>
                                                    6xx8<span> USD</span>
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="home__games">
                                        <div className="home__games-tab">
                                            <div className="home__games-tab-container">
                                                <Slider
                                                    {...gameTabSlideSetting}
                                                >
                                                    {gameTabData.map((game) => {
                                                        return (
                                                            <div
                                                                onClick={() =>
                                                                    this.handleTabGame(
                                                                        game.key,
                                                                        game.items
                                                                    )
                                                                }
                                                                key={game.key}
                                                                className={
                                                                    game.key ===
                                                                        this
                                                                            .state
                                                                            .gameTabActive ||
                                                                    (game.default &&
                                                                        !this
                                                                            .state
                                                                            .gameTabActive)
                                                                        ? "home__games-tab-item home__games-tab-item-active"
                                                                        : "home__games-tab-item"
                                                                }
                                                            >
                                                                {game.name}
                                                            </div>
                                                        );
                                                    })}
                                                </Slider>
                                            </div>
                                        </div>
                                        <div className="home__games-content">
                                            {this.state.gameTabContent ? (
                                                <React.Fragment>
                                                    <React.Fragment>
                                                        {this.state.gameTabContent.map(
                                                            (game, index) => {
                                                                return game.enabled &&
                                                                    game.show ? (
                                                                    <div
                                                                        key={
                                                                            game.name +
                                                                            "_enabled_" +
                                                                            index
                                                                        }
                                                                        className="home__games-content-item"
                                                                    >
                                                                        <NavLink
                                                                            to={
                                                                                game.linkBet
                                                                                    ? Func.getRoute(
                                                                                          game.linkBet
                                                                                      )
                                                                                    : "#"
                                                                            }
                                                                            className="home__games-content-info"
                                                                        >
                                                                            <img
                                                                                src={
                                                                                    game.image
                                                                                }
                                                                                alt={
                                                                                    game.name
                                                                                }
                                                                            />
                                                                            <h3>
                                                                                {
                                                                                    game.name
                                                                                }
                                                                            </h3>
                                                                            <span>
                                                                                {
                                                                                    game.meta
                                                                                }
                                                                            </span>
                                                                        </NavLink>
                                                                        <div className="home__games-content-link">
                                                                            <NavLink
                                                                                to={Func.getRoute(
                                                                                    game.linkBet
                                                                                )}
                                                                            >
                                                                                Cược
                                                                                ngay
                                                                            </NavLink>
                                                                            <NavLink
                                                                                to={Func.getRoute(
                                                                                    game.linkResult
                                                                                )}
                                                                            >
                                                                                Kết
                                                                                quả
                                                                            </NavLink>
                                                                        </div>
                                                                        <NavLink
                                                                            to={
                                                                                game.linkBet
                                                                                    ? Func.getRoute(
                                                                                          game.linkBet
                                                                                      )
                                                                                    : "#"
                                                                            }
                                                                            className="home__games-content-bet"
                                                                        >
                                                                            Cược
                                                                            ngay
                                                                        </NavLink>
                                                                    </div>
                                                                ) : (
                                                                    <React.Fragment
                                                                        key={
                                                                            game.name +
                                                                            "_enabled_" +
                                                                            index
                                                                        }
                                                                    ></React.Fragment>
                                                                );
                                                            }
                                                        )}
                                                    </React.Fragment>
                                                    <React.Fragment>
                                                        {this.state.gameTabContent.map(
                                                            (game, index) => {
                                                                return !game.enabled &&
                                                                    game.show ? (
                                                                    <div
                                                                        onClick={(
                                                                            event
                                                                        ) =>
                                                                            Func.alert(
                                                                                "Trò chơi hiện đang bảo trì !"
                                                                            )
                                                                        }
                                                                        key={
                                                                            game.name +
                                                                            "_disabled_" +
                                                                            index
                                                                        }
                                                                        className="home__games-content-item home__games-content-item-disabled"
                                                                    >
                                                                        <div className="home__games-content-info">
                                                                            <img
                                                                                src={
                                                                                    game.image
                                                                                }
                                                                                alt={
                                                                                    game.name
                                                                                }
                                                                            />
                                                                            <h3>
                                                                                {
                                                                                    game.name
                                                                                }
                                                                            </h3>
                                                                            <span>
                                                                                {
                                                                                    game.meta
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                        <div className="home__games-content-link">
                                                                            <div>
                                                                                Cược
                                                                                ngay
                                                                            </div>
                                                                            <div>
                                                                                Kết
                                                                                quả
                                                                            </div>
                                                                        </div>
                                                                        <div className="home__games-content-bet">
                                                                            Cược
                                                                            ngay
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <React.Fragment
                                                                        key={
                                                                            game.name +
                                                                            "_disabled_" +
                                                                            index
                                                                        }
                                                                    ></React.Fragment>
                                                                );
                                                            }
                                                        )}
                                                    </React.Fragment>
                                                </React.Fragment>
                                            ) : (
                                                <div className="home__games-content-updating">
                                                    <img
                                                        src="/images/coming.png"
                                                        alt="Updating..."
                                                    />
                                                    <span>Đang cập nhật</span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Partner></Partner>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(React.memo(Home));
