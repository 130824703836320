import Func from "@/app/comFunc/Func";
import Icon from "@/components/comIcon/Icon";
import Logo from "@/components/comLogo/Logo";
import React from "react";
import { Link } from "react-router-dom";
import "./header.scss";

class Header extends React.Component {
    constructor(props) {
        super(props);
        window.Header = this;
        this.state = {
            is_login: false,
            username: Func.getStorage("username"),
            balance: Func.getStorage("balance"),
            image: Func.getStorage("image"),
            vip: Func.getStorage("vip"),
        };
    }
    _isMounted = false;
    componentDidMount = () => {
        this._isMounted = true;
        this.interval = setInterval(this.check, 1);
    };
    componentWillUnmount = () => {
        this._isMounted = false;
        clearInterval(this.interval);
    };
    check = () => {
        if (Func.getStorage("username") !== this.state.username) {
            this._isMounted &&
                this.setState({
                    ...this.state,
                    username: Func.getStorage("username"),
                });
        }
        if (Func.getStorage("balance") !== this.state.balance) {
            this._isMounted &&
                this.setState({
                    ...this.state,
                    balance: Func.getStorage("balance"),
                });
        }
        if (Func.getStorage("image") !== this.state.image) {
            this._isMounted &&
                this.setState({
                    ...this.state,
                    image: Func.getStorage("image"),
                });
        }
        if (Func.getStorage("vip") !== this.state.vip) {
            this._isMounted &&
                this.setState({
                    ...this.state,
                    vip: Func.getStorage("vip"),
                });
        }
        if (
            Func.getStorage("token") &&
            Func.getStorage("token_expired") === false
        ) {
            this.state.is_login !== true &&
                this._isMounted &&
                this.setState({
                    ...this.state,
                    is_login: true,
                });
            return true;
        } else {
            this.state.is_login !== false &&
                this._isMounted &&
                this.setState({
                    ...this.state,
                    is_login: false,
                });
            return false;
        }
    };
    HeaderInfo = () => {
        return (
            <Link to={Func.getRoute("account")} className="header__right">
                <div className="header__right-info">
                    <div>
                        <img
                            src={
                                "images/vips/vip" +
                                (this.state.vip ? this.state.vip : 1) +
                                ".png"
                            }
                            style={{ height: "15px" }}
                            alt=""
                        />
                        <strong>
                            {this.state.username ? this.state.username : "..."}
                        </strong>
                    </div>
                    <span>
                        <i>Số dư: </i>
                        {this.state.balance
                            ? Func.formatBalance(this.state.balance)
                            : "..."}
                    </span>
                </div>
                <img
                    src={
                        this.state.image
                            ? Func.getImage(this.state.image)
                            : Func.getAvatarDefault()
                    }
                    alt=""
                />
            </Link>
        );
    };
    render() {
        return (
            <React.Fragment>
                {this.props.heading ? (
                    <React.Fragment>
                        <div
                            {...this.props}
                            className={
                                "header" +
                                (this.state.is_login ? " header__logged" : "")
                            }
                        >
                            <Link
                                to={-1}
                                className="header__left"
                                title="Quay lại"
                            >
                                <Icon
                                    type="fas"
                                    className="fa-arrow-left"
                                ></Icon>
                            </Link>
                            <div className="header__heading">
                                <h3>{this.props.heading}</h3>
                            </div>
                            <Link
                                to={Func.getRoute("home")}
                                className="header__right"
                            ></Link>
                        </div>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div
                            {...this.props}
                            className={
                                "header" +
                                (this.state.is_login ? " header__logged" : "")
                            }
                        >
                            {this.state.is_login ? (
                                <React.Fragment>
                                    <Link
                                        to={Func.getRoute("notification")}
                                        className="header__left"
                                        title="Thông báo"
                                    >
                                        <Icon type="fas" className="fa-bell">
                                            <span>0</span>
                                        </Icon>
                                    </Link>
                                    <div className="header__middle">
                                        <Logo
                                            title="Logo"
                                            src="/images/marina-bay-sanebgs.png"
                                        ></Logo>
                                    </div>
                                    <this.HeaderInfo></this.HeaderInfo>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <Link
                                        to={Func.getRoute("notification")}
                                        className="header__left"
                                        title="Thông báo"
                                    >
                                        <Icon type="fas" className="fa-bell">
                                            <span>0</span>
                                        </Icon>
                                    </Link>
                                    <div className="header__middle">
                                        <Logo
                                            title="Logo"
                                            src="/images/marina-bay-sanebgs.png"
                                        ></Logo>
                                    </div>
                                    <Link
                                        to={Func.getRoute("login")}
                                        className="header__right"
                                        title="Đăng nhập / Đăng ký"
                                    >
                                        <Icon
                                            type="fas"
                                            className="fa-right-to-bracket"
                                        ></Icon>
                                    </Link>
                                </React.Fragment>
                            )}
                        </div>
                        <div className="menu"></div>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}
export default Header;
