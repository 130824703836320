import React from "react";
import "./bet.scss";
import withRouter from "@/app/comRouter/withRouter";
import Header from "@/layouts/comHeader/Header";
import Func from "@/app/comFunc/Func";
import NoData from "@/components/comNoData/NoData";
import Label from "@/components/comLabel/Label";
import Text from "@/components/comText/Text";
import DiceResult from "@/components/comDiceResult/DiceResult";
import BallResult from "@/components/comBallResult/BallResult";

class Bet extends React.Component {
    constructor(props) {
        super(props);
        window.Bet = this;
        this.state = {
            bets: undefined,
        };
    }
    _isMounted = false;
    componentDidMount = () => {
        this._isMounted = true;
        this.load();
    };
    componentWillUnmount() {
        this._isMounted = false;
    }
    load = () => {
        Func.send(
            Func.getApi("client.mGame.bet.index", "path"),
            Func.getApi("client.mGame.bet.index", "method"),
            null,
            (res) => {
                if (res.data && res.data.data) {
                    let data = res.data.data;
                    this._isMounted &&
                        this.setState({
                            ...this.state,
                            bets: data,
                        });
                }
            },
            (res) => {
                console.clear();
            }
        );
    };
    render() {
        return (
            <React.Fragment>
                <Header heading={Func.getRoute("account.bet", "text")}></Header>
                <div className="bet-history">
                    {this.state.bets && this.state.bets.length ? (
                        <div className="bet-history__list">
                            {this.state.bets.map((item) => {
                                return (
                                    <div
                                        key={item._id}
                                        className={
                                            "bet-history__item bet-history__item-" +
                                            (parseInt(item.process) === 1
                                                ? "success"
                                                : parseInt(item.process) === 2
                                                ? "warning"
                                                : "default")
                                        }
                                    >
                                        <div className="bet-history__item-head">
                                            <strong>
                                                {item.game_name}
                                                <span>
                                                    {" "}
                                                    (
                                                    {Func.formatDate(
                                                        item.created_at
                                                    )}
                                                    )
                                                </span>
                                            </strong>
                                            {item.result === "win" ? (
                                                <Label className="label__success">
                                                    {item.result_text}
                                                </Label>
                                            ) : item.result === "lose" ? (
                                                <Label className="label__danger">
                                                    {item.result_text}
                                                </Label>
                                            ) : (
                                                <Label className="label__warning">
                                                    {item.process_text}
                                                </Label>
                                            )}
                                        </div>
                                        <div className="bet-history__item-row">
                                            <strong>Số kỳ: </strong>
                                            <Text>{item.game_no}</Text>
                                        </div>
                                        <div className="bet-history__item-row">
                                            <strong>Số tiền cược: </strong>
                                            <strong className="color__danger">
                                                {Func.formatBalance(
                                                    item.balance
                                                )}
                                            </strong>
                                        </div>
                                        <div className="bet-history__item-row">
                                            <strong>Giá trị cược: </strong>
                                            <Text
                                                title={
                                                    item.bet_name +
                                                    (item.bet_type_odds
                                                        ? " (" +
                                                          item.bet_type_odds +
                                                          ")"
                                                        : "")
                                                }
                                            >
                                                {item.bet_name}
                                                {item.bet_type_odds ? (
                                                    <span
                                                        style={{
                                                            color: "green",
                                                        }}
                                                    >
                                                        {" (" +
                                                            item.bet_type_odds +
                                                            ")"}
                                                    </span>
                                                ) : (
                                                    ""
                                                )}
                                            </Text>
                                        </div>
                                        <div className="bet-history__item-row">
                                            <strong>Nhận về ước tính: </strong>
                                            <strong className="color__success">
                                                {Func.formatBalance(
                                                    Func.filterNumeric(
                                                        item.balance
                                                    ) *
                                                        Func.filterNumeric(
                                                            item.bet_type_odds
                                                        )
                                                )}
                                            </strong>
                                        </div>
                                        <div className="bet-history__item-row">
                                            <strong>KQ trò chơi: </strong>
                                            {parseInt(item.process) === 1 ? (
                                                <React.Fragment>
                                                    {item.game_type ===
                                                        "game_three_dice_one_min" ||
                                                    item.game_type ===
                                                        "game_three_dice_three_min" ||
                                                    item.game_type ===
                                                        "game_three_dice_five_min" ||
                                                    item.game_type ===
                                                        "game_three_dice_ten_min" ? (
                                                        // <DiceResult
                                                        //     array={
                                                        //         item.game_boms
                                                        //     }
                                                        // ></DiceResult>
                                                        <BallResult
                                                            big
                                                            effect
                                                            array={
                                                                item.game_boms
                                                            }
                                                        ></BallResult>
                                                    ) : (
                                                        <React.Fragment></React.Fragment>
                                                    )}
                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment></React.Fragment>
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    ) : (
                        <NoData></NoData>
                    )}
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(React.memo(Bet));
