import React from "react";
import "./game.scss";
import withRouter from "@/app/comRouter/withRouter";
import GameHead from "./GameHead";
import Func from "@/app/comFunc/Func";
import Countdown, { zeroPad } from "react-countdown";
import DiceResult from "@/components/comDiceResult/DiceResult";
import DiceCounter from "@/components/comDiceCounter/DiceCounter";
import Icon from "@/components/comIcon/Icon";
import Slider from "react-slick";
import Input from "@/components/comForm/comInput/Input";
import Const from "@/app/comConst/Const";
import NoData from "@/components/comNoData/NoData";
import { Link } from "react-router-dom";
import BallCounter from "@/components/comBallCounter/BallCounter";
import BallResult from "@/components/comBallResult/BallResult";
import Page404 from "../comPage404/Page404";
import { isMobile } from "react-device-detect";

const gamePlaySlideSetting = {
    dots: false,
    infinite: false,
    speed: 100,
    slidesToShow: 6,
    slidesToScroll: 6,
    adaptiveHeight: false,
    margin: 10,
    arrows: true,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 6,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 380,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            },
        },
    ],
};
class GameThreeDiceOneMin extends React.Component {
    constructor(props) {
        super(props);
        window.GameThreeDiceOneMin = this;
        this.state = {
            game_current: undefined,
            game_previous: undefined,
            game_opening: false,
            bets_type: undefined,
            bet_type_select: undefined,
            plays_type_select: undefined,
            play_type_select: undefined,
            bets_type_select: undefined,
            cart: {},
            cart_temp: {},
            balance: 1,
            show_chips: false,
            show_cart: false,
            odds_head: undefined,
            chip:
                Func.getStorage("chip") &&
                typeof Func.getStorage("chip") === "string"
                    ? JSON.parse(Func.getStorage("chip"))
                    : undefined,
        };
    }
    _isMounted = false;
    componentDidMount = () => {
        this._isMounted = true;
        this.load();
        if (this.state.chip && this.state.chip.value) {
            this._isMounted &&
                this.setState({
                    ...this.state,
                    balance: this.state.chip.value,
                });
        }
        window.GameHead._isMounted &&
            window.GameHead.setState({
                ...window.GameHead.state,
                menuIsOpen: false,
            });
    };
    componentWillUnmount() {
        this._isMounted &&
            this.setState({
                ...this.state,
                cart: {},
            });
        this._isMounted = false;
    }
    load = () => {
        this._isMounted &&
            Func.send(
                Func.getApi("client.mGame.gameThreeDiceOneMin.load", "path"),
                Func.getApi("client.mGame.gameThreeDiceOneMin.load", "method"),
                null,
                (res) => {
                    if (res.data && res.data.attach) {
                        let attach = res.data.attach;
                        this._isMounted &&
                            this.setState(
                                {
                                    ...this.state,
                                    game_current: attach.game_current,
                                    game_previous: attach.game_previous,
                                    bets_type: attach.bets_type,
                                },
                                () => {
                                    if (
                                        this.state.bets_type &&
                                        !this.state.bet_type_select
                                    ) {
                                        this.setBetType("official");
                                    }
                                }
                            );
                    }
                    if (res.data && res.data.data) {
                        let data = res.data.data;
                        Func.setUserStorage(data);
                        window.GameHead._isMounted &&
                            window.GameHead.setState({
                                ...window.GameHead.state,
                                username: data.username,
                                balance: data.balance,
                                image: data.image,
                            });
                    }
                },
                (res) => {
                    // console.clear();
                }
            );
    };
    setOpenning = (opening) => {
        this._isMounted &&
            this.setState({
                ...this.state,
                game_opening: opening,
            });
    };
    setBetType = (bet_type, select = 0) => {
        if (
            this.state.bets_type &&
            bet_type &&
            (bet_type === "classic" || bet_type === "official") &&
            this._isMounted &&
            this.state.bets_type[bet_type]
        ) {
            let bets_type = this.state.bets_type[bet_type].items;
            let bets_type_select = bets_type[select];
            let plays = bets_type_select.plays;
            let rows = plays.map((play) => {
                return play.row.map((row) => {
                    return row.bets;
                });
            });
            rows = [].concat.apply([], rows);
            rows = [].concat.apply([], rows);
            let odds = rows.map((row) => {
                return row.odds;
            });
            let min = Math.min(...odds),
                max = Math.max(...odds);
            this.setState({
                ...this.state,
                bet_type_select: bet_type,
                plays_type_select: bets_type,
                bets_type_select: bets_type_select,
                play_type_select: select,
                odds_head:
                    "Tỷ lệ cược: gấp <span>" +
                    min +
                    "</span>" +
                    (max > min ? " - <span>" + max + "</span>" : "") +
                    " lần",
            });
        }
    };
    orderBet = (play, bet) => {
        let key = play.id + "_" + bet.id;
        let cart = this.state.cart ? this.state.cart : {};
        let item = undefined;
        switch (play.id) {
            // case 'BPairsPlus1_PairsPlus1': // chọn 2 lần
            //    let cart_temp = this.state.cart_temp[play.id] ? this.state.cart_temp[play.id] : {};
            //    cart_temp = {
            //       ...cart_temp,
            //       [key]: {
            //          ...bet,
            //          balance: Func.filterNumeric(this.state.balance),
            //          bet_name: '[' + play.bet_type.replace(/\s/g, '') + ']{' + play.name.replace(/\s/g, '') + '}',
            //          bet_type: play.id,
            //          bet_content: bet.id,
            //       },
            //    };
            //    this._isMounted &&
            //       this.setState({
            //          ...this.state,
            //          cart_temp: {
            //             ...this.state.cart_temp,
            //             [play.id]: {
            //                ...this.state.cart_temp[play.id],
            //                [key]: {
            //                   ...bet,
            //                   balance: Func.filterNumeric(this.state.balance),
            //                   bet_name: '[' + play.bet_type.replace(/\s/g, '') + ']{' + play.name.replace(/\s/g, '') + '}',
            //                   bet_type: play.id,
            //                   bet_content: bet.id,
            //                },
            //             },
            //          },
            //       });
            //    console.log(this.state.cart_temp);
            //    break;
            default:
                item = {
                    ...bet,
                    balance: Func.filterNumeric(this.state.balance),
                    bet_name:
                        "[" +
                        play.bet_type.replace(/\s/g, "") +
                        "]{" +
                        play.name.replace(/\s/g, "") +
                        "}",
                    bet_type: play.id,
                    bet_content: bet.id,
                    key: play.id + "_" + bet.id,
                };
                break;
        }
        if (item) {
            let active = cart && cart[key] ? false : true;
            if (active) {
                cart[key] = item;
            } else {
                cart[key] && delete cart[key];
            }
            // console.log(cart);
            this._isMounted &&
                this.setState({
                    ...this.state,
                    cart: cart,
                });
        }
    };
    getSumCart = () => {
        let cart = this.state.cart ? this.state.cart : {};
        let sum = Object.keys(cart)
            .map((key) => {
                return Func.filterNumeric(cart[key].balance);
            })
            .reduce((partialSum, a) => partialSum + a, 0);
        return Func.formatBalanceShort(sum);
    };
    clearCart = () => {
        if (this.state.cart && Object.keys(this.state.cart).length) {
            Func.confirmAlert(
                "Bạn có thực sự muốn xóa giỏ hàng ? ",
                (confirmed) => {
                    if (confirmed) {
                        this._isMounted &&
                            this.setState({
                                ...this.state,
                                cart: {},
                            });
                    }
                }
            );
        } else {
            Func.alert("Giỏ cược hiện đang trống !");
        }
    };
    showCart = () => {
        if (this.state.cart && Object.keys(this.state.cart).length) {
            this._isMounted &&
                this.setState({
                    ...this.state,
                    show_cart: true,
                });
        } else {
            Func.alert("Giỏ cược hiện đang trống !");
        }
    };
    hideCart = () => {
        this._isMounted &&
            this.setState({
                ...this.state,
                show_cart: false,
            });
    };
    showChips = () => {
        this._isMounted &&
            this.setState({
                ...this.state,
                show_chips: true,
            });
    };
    hideChips = () => {
        this._isMounted &&
            this.setState({
                ...this.state,
                show_chips: false,
            });
    };
    setChip = (value) => {
        let balance = this.state.balance
            ? Func.filterNumeric(this.state.balance)
            : 0;
        balance = balance === 1 ? 0 : balance;

        let cart = this.state.cart ? this.state.cart : {};
        let newCart = undefined;
        Object.keys(cart).forEach((key) => {
            cart[key].balance = balance + value.value;
        });
        newCart = cart;
        // console.log(newCart);
        this._isMounted &&
            this.setState({
                ...this.state,
                chip: value,
                show_chips: false,
                balance: balance + value.value,
                cart: newCart ? newCart : this.state.cart,
            });
        Func.setStorage({ chip: JSON.stringify(value) });
    };
    setBalanceCartItem = (event, value, item) => {
        // console.log(item);
        // console.log(value);
        let cart = this.state.cart ? this.state.cart : {};
        // console.log(cart);
        let itemNew = {
            ...item,
            balance: Func.filterNumeric(value),
        };
        cart[item.key] = itemNew;
        this._isMounted &&
            this.setState({
                ...this.state,
                cart: cart,
            });
    };
    setBalance = (event, value) => {
        let cart = this.state.cart ? this.state.cart : {};
        Object.keys(cart).forEach((key) => {
            cart[key].balance = value;
        });
        this._isMounted &&
            this.setState({
                ...this.state,
                cart: cart,
                balance: value,
            });
    };
    removeCartItem = (item) => {
        let cart = this.state.cart ? this.state.cart : {};
        cart[item.key] && delete cart[item.key];
        this._isMounted &&
            this.setState({
                ...this.state,
                cart: cart,
            });
    };
    handleBet = (event) => {
        event.preventDefault();
        if (this.state.cart && Object.keys(this.state.cart).length) {
            let cart = this.state.cart ? this.state.cart : {};
            if (this.state.game_current) {
                let sum = Object.keys(cart)
                    .map((key) => {
                        return Func.filterNumeric(cart[key].balance);
                    })
                    .reduce((partialSum, a) => partialSum + a, 0);
                if (sum > Func.getStorage("balance")) {
                    Func.alert("Số dư của quý khách không đủ !");
                } else {
                    Func.confirmAlert(
                        "Xác nhận đặt cược ? <br>Tài khoản của quý khách sẽ bị trừ số tiền cược tương ứng.",
                        (confirmed) => {
                            if (confirmed) {
                                Func.send(
                                    Func.getApi(
                                        "client.mGame.gameThreeDiceOneMin.bet",
                                        "path"
                                    ),
                                    Func.getApi(
                                        "client.mGame.gameThreeDiceOneMin.bet",
                                        "method"
                                    ),
                                    {
                                        game_id: this.state.game_current._id,
                                        game_type: "game_three_dice_one_min",
                                        cart: cart,
                                    },
                                    (res) => {
                                        if (res.data.code === 200) {
                                            res.data.message &&
                                                Func.notifySuccess(
                                                    res.data.message
                                                );
                                            this._isMounted &&
                                                this.setState({
                                                    ...this.state,
                                                    cart: {},
                                                });
                                            if (res.data && res.data.data) {
                                                let data = res.data.data;
                                                Func.setUserStorage(data);
                                            }
                                        } else {
                                            Func.handleErrors(res, this);
                                        }
                                    },
                                    (res) => {
                                        Func.handleErrors(res, this);
                                    }
                                );
                            }
                        }
                    );
                }
            }
        } else {
            Func.alert("Giỏ cược hiện đang trống !");
        }
    };
    render() {
        return (
            <React.Fragment>
                <GameHead
                    heading={Func.getRoute("game.GameThreeDiceOneMin", "text")}
                ></GameHead>
                <React.Fragment>
                    {this.state.game_previous && this.state.game_current ? (
                        <div className="game">
                            <div className="game__result">
                                <div className="game__result-left">
                                    <Link to={Func.getRoute("account.bet")}>
                                        <Icon
                                            type="far"
                                            className="fa-arrow-up-right-dots"
                                        ></Icon>
                                    </Link>
                                    <Link
                                        to={Func.getRoute(
                                            "result.GameThreeDiceOneMin"
                                        )}
                                    >
                                        <Icon
                                            type="far"
                                            className="fa-square-poll-vertical"
                                        ></Icon>
                                    </Link>
                                </div>
                                <div className="game__result-middle">
                                    <div className="game__result-head">
                                        {this.state.game_opening ? (
                                            <React.Fragment>
                                                Đang mở kỳ{" "}
                                                <span>
                                                    {
                                                        this.state.game_current
                                                            .no_num
                                                    }
                                                </span>{" "}
                                                hôm nay ...
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment>
                                                Kết quả kỳ{" "}
                                                <span>
                                                    {
                                                        this.state.game_previous
                                                            .no_num
                                                    }
                                                </span>{" "}
                                                hôm nay
                                            </React.Fragment>
                                        )}
                                    </div>
                                    {this.state.game_current.end && (
                                        <React.Fragment>
                                            <div className="game__result-content">
                                                <Countdown
                                                    onComplete={() =>
                                                        this.setOpenning(false)
                                                    }
                                                    key={
                                                        this.state.game_current
                                                            ._id + "__result"
                                                    }
                                                    date={
                                                        this.state.game_current
                                                            .end
                                                    }
                                                    renderer={({
                                                        hours,
                                                        minutes,
                                                        seconds,
                                                        completed,
                                                    }) => {
                                                        if (
                                                            hours === 0 &&
                                                            minutes === 0 &&
                                                            seconds <= 10
                                                        ) {
                                                            return (
                                                                <BallCounter
                                                                    callback={() =>
                                                                        this.setOpenning(
                                                                            true
                                                                        )
                                                                    }
                                                                    big
                                                                ></BallCounter>
                                                            );
                                                        } else {
                                                            return (
                                                                <BallResult
                                                                    big
                                                                    effect
                                                                    array={[
                                                                        this
                                                                            .state
                                                                            .game_previous
                                                                            .bom_1,
                                                                        this
                                                                            .state
                                                                            .game_previous
                                                                            .bom_2,
                                                                        this
                                                                            .state
                                                                            .game_previous
                                                                            .bom_3,
                                                                    ]}
                                                                ></BallResult>
                                                            );
                                                        }
                                                    }}
                                                ></Countdown>
                                            </div>
                                            <div className="game__result-countdown">
                                                <Icon
                                                    type="far"
                                                    className="fa-clock-rotate-left"
                                                ></Icon>
                                                <span>
                                                    <Countdown
                                                        onComplete={this.load}
                                                        key={
                                                            this.state
                                                                .game_current
                                                                ._id +
                                                            "__countdown"
                                                        }
                                                        date={
                                                            new Date(
                                                                this.state.game_current.end
                                                            )
                                                        }
                                                        renderer={({
                                                            hours,
                                                            minutes,
                                                            seconds,
                                                            completed,
                                                        }) => {
                                                            return (
                                                                zeroPad(hours) +
                                                                ":" +
                                                                zeroPad(
                                                                    minutes
                                                                ) +
                                                                ":" +
                                                                zeroPad(seconds)
                                                            );
                                                        }}
                                                    ></Countdown>
                                                </span>
                                            </div>
                                        </React.Fragment>
                                    )}
                                </div>
                                <div className="game__result-right">
                                    <div
                                        onClick={() =>
                                            this.setBetType("classic")
                                        }
                                        className={
                                            this.state.bet_type_select ===
                                            "classic"
                                                ? "active"
                                                : ""
                                        }
                                    >
                                        Cổ điển
                                    </div>
                                    <div
                                        onClick={() =>
                                            this.setBetType("official")
                                        }
                                        className={
                                            this.state.bet_type_select ===
                                            "official"
                                                ? "active"
                                                : ""
                                        }
                                    >
                                        Chính thức
                                    </div>
                                </div>
                            </div>
                            {this.state.odds_head && (
                                <div
                                    className="game__odds"
                                    dangerouslySetInnerHTML={{
                                        __html: this.state.odds_head,
                                    }}
                                ></div>
                            )}
                            {this.state.plays_type_select && (
                                <React.Fragment>
                                    <div className="game__play">
                                        <Slider {...gamePlaySlideSetting}>
                                            {this.state.plays_type_select.map(
                                                (item, index) => {
                                                    return (
                                                        <div
                                                            key={
                                                                "game__play_" +
                                                                index
                                                            }
                                                            onClick={() =>
                                                                this.setBetType(
                                                                    this.state
                                                                        .bet_type_select,
                                                                    index
                                                                )
                                                            }
                                                            className={
                                                                "game__play-item" +
                                                                (this.state
                                                                    .play_type_select ===
                                                                index
                                                                    ? " active"
                                                                    : index ===
                                                                          0 &&
                                                                      !this
                                                                          .state
                                                                          .play_type_select
                                                                    ? " active"
                                                                    : "")
                                                            }
                                                        >
                                                            {item.name}
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </Slider>
                                    </div>
                                    <div className="game__bets">
                                        {this.state.bets_type_select &&
                                            this.state.bets_type_select.plays.map(
                                                (play, play_index) => {
                                                    return (
                                                        play.row && (
                                                            <React.Fragment
                                                                key={
                                                                    play +
                                                                    play_index
                                                                }
                                                            >
                                                                <div
                                                                    className={
                                                                        "game__bets-item"
                                                                    }
                                                                >
                                                                    <div className="game__bets-head">
                                                                        <span>
                                                                            {
                                                                                play.name
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                    {play.row.map(
                                                                        (
                                                                            row,
                                                                            row_index
                                                                        ) => {
                                                                            return (
                                                                                <React.Fragment
                                                                                    key={
                                                                                        row +
                                                                                        row_index
                                                                                    }
                                                                                >
                                                                                    {row.name && (
                                                                                        <div className="game__bets-head-row">
                                                                                            <span>
                                                                                                {
                                                                                                    row.name
                                                                                                }
                                                                                            </span>
                                                                                        </div>
                                                                                    )}
                                                                                    <div className="game__bets-content">
                                                                                        {row.bets.map(
                                                                                            (
                                                                                                item,
                                                                                                item_index
                                                                                            ) => {
                                                                                                return (
                                                                                                    <div
                                                                                                        onClick={() =>
                                                                                                            this.orderBet(
                                                                                                                {
                                                                                                                    ...play,
                                                                                                                    bet_type:
                                                                                                                        this
                                                                                                                            .state
                                                                                                                            .bets_type_select
                                                                                                                            .name,
                                                                                                                },
                                                                                                                item
                                                                                                            )
                                                                                                        }
                                                                                                        key={
                                                                                                            item +
                                                                                                            item_index
                                                                                                        }
                                                                                                        className={
                                                                                                            "game__bet-item" +
                                                                                                            (this
                                                                                                                .state
                                                                                                                .cart &&
                                                                                                            this
                                                                                                                .state
                                                                                                                .cart[
                                                                                                                play.id +
                                                                                                                    "_" +
                                                                                                                    item.id
                                                                                                            ]
                                                                                                                ? " active"
                                                                                                                : "")
                                                                                                        }
                                                                                                    >
                                                                                                        <strong>
                                                                                                            {
                                                                                                                item.name
                                                                                                            }
                                                                                                        </strong>
                                                                                                        {play
                                                                                                            .row
                                                                                                            .length ===
                                                                                                            1 && (
                                                                                                            <span>
                                                                                                                {
                                                                                                                    item.odds
                                                                                                                }
                                                                                                            </span>
                                                                                                        )}
                                                                                                        {this
                                                                                                            .state
                                                                                                            .cart &&
                                                                                                        this
                                                                                                            .state
                                                                                                            .cart[
                                                                                                            play.id +
                                                                                                                "_" +
                                                                                                                item.id
                                                                                                        ] ? (
                                                                                                            <i>
                                                                                                                {Func.formatBalanceShort(
                                                                                                                    this
                                                                                                                        .state
                                                                                                                        .cart[
                                                                                                                        play.id +
                                                                                                                            "_" +
                                                                                                                            item.id
                                                                                                                    ]
                                                                                                                        .balance
                                                                                                                )}
                                                                                                            </i>
                                                                                                        ) : (
                                                                                                            <React.Fragment></React.Fragment>
                                                                                                        )}
                                                                                                    </div>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                    </div>
                                                                                </React.Fragment>
                                                                            );
                                                                        }
                                                                    )}
                                                                </div>
                                                            </React.Fragment>
                                                        )
                                                    );
                                                }
                                            )}
                                    </div>
                                </React.Fragment>
                            )}
                        </div>
                    ) : (
                        <React.Fragment></React.Fragment>
                    )}
                    <div className="panel">
                        <div className="panel__info">
                            <div className="panel__info-left">
                                <div className="chips">
                                    <div
                                        className={
                                            "chips__item chips__item-" +
                                            (this.state.chip
                                                ? this.state.chip.key
                                                : 7)
                                        }
                                        onClick={this.showChips}
                                    >
                                        {this.state.chip
                                            ? this.state.chip.name
                                            : "Chip"}
                                    </div>
                                </div>
                            </div>
                            <div className="panel__info-middle">
                                <Input
                                    // format="float"
                                    // decimal={2}
                                    min={1}
                                    // onInput={(event) =>
                                    //     Func.setValueInput(event, this)
                                    // }
                                    onInput={(event, value) =>
                                        this.setBalance(event, value)
                                    }
                                    // suffix={" $/click"}
                                    type="number"
                                    pattern="[0-9]*"
                                    inputMode="numeric"
                                    name="balance"
                                    placeholder="..."
                                    value={this.state.balance}
                                ></Input>
                            </div>
                            <div className="panel__info-right">
                                <div className="panel__info-right-item">
                                    <span>Đã chọn: </span>
                                    <strong>
                                        {this.state.cart
                                            ? Object.keys(this.state.cart)
                                                  .length
                                            : 0}
                                    </strong>
                                    <span> cược</span>
                                </div>
                                <div className="panel__info-right-item">
                                    <span>Tổng tiền: </span>
                                    <strong>
                                        {this.state.cart
                                            ? this.getSumCart()
                                            : Func.formatBalanceShort(0)}
                                    </strong>
                                </div>
                            </div>
                        </div>
                        <div className="panel__action">
                            <div className="panel__action-left">
                                <div
                                    className="panel__action-remove"
                                    onClick={this.clearCart}
                                >
                                    <Icon
                                        type="fas"
                                        className="fa-eraser"
                                    ></Icon>
                                    <span>Xóa</span>
                                </div>
                                <div
                                    className="panel__action-cart"
                                    onClick={this.showCart}
                                >
                                    <Icon
                                        type="fas"
                                        className="fa-cart-circle-check"
                                    ></Icon>
                                    <span>Giỏ</span>
                                    <strong>
                                        (
                                        {this.state.cart
                                            ? Object.keys(this.state.cart)
                                                  .length
                                            : 0}
                                        )
                                    </strong>
                                </div>
                            </div>
                            <div className="panel__action-right">
                                {/* <div className="panel__action-add">
                        <Icon type="fas" className="fa-plus"></Icon>
                        <span>Thêm</span>
                     </div> */}
                                <div
                                    className="panel__action-submit"
                                    onClick={this.handleBet}
                                >
                                    <Icon
                                        type="fas"
                                        className="fa-coins"
                                    ></Icon>
                                    <span>Đặt cược</span>
                                </div>
                            </div>
                        </div>
                        {this.state.show_chips && (
                            <div className="panel__chips animate__animated animate__bounceInUp animate__faster">
                                <div className="panel__chips-head">
                                    <span>Chọn Chip:</span>
                                    <Icon
                                        type="fas"
                                        className="fa-xmark"
                                        onClick={this.hideChips}
                                    ></Icon>
                                </div>
                                <div className="chips">
                                    {Const.chips.map((chip) => {
                                        return (
                                            <div
                                                key={"chip_" + chip.key}
                                                className={
                                                    "chips__item chips__item-" +
                                                    chip.key
                                                }
                                                onClick={(event) =>
                                                    this.setChip(chip)
                                                }
                                            >
                                                {chip.name}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </div>
                    {this.state.show_cart && (
                        <div className="cart animate__animated animate__bounceInLeft animate__faster">
                            <div className="cart__head">
                                <span>Giỏ cược</span>
                                <Icon
                                    type="fas"
                                    className="fa-xmark"
                                    onClick={this.hideCart}
                                ></Icon>
                            </div>
                            <div className="cart__content">
                                {this.state.cart &&
                                Object.keys(this.state.cart).length ? (
                                    Object.keys(this.state.cart).map((key) => {
                                        return (
                                            <div
                                                className="cart__item"
                                                key={"cart__item_" + key}
                                            >
                                                <div className="cart__item-info">
                                                    <div>
                                                        <span>
                                                            Giá trị cược:{" "}
                                                        </span>
                                                        <strong>
                                                            {
                                                                this.state.cart[
                                                                    key
                                                                ].name
                                                            }
                                                        </strong>
                                                    </div>
                                                    <div>
                                                        <span>Tiền cược: </span>
                                                        <strong>
                                                            {Func.formatBalance(
                                                                this.state.cart[
                                                                    key
                                                                ].balance
                                                            )}
                                                        </strong>
                                                    </div>
                                                    <div>
                                                        <span>Loại cược: </span>
                                                        <strong>
                                                            {
                                                                this.state.cart[
                                                                    key
                                                                ].bet_name
                                                            }
                                                        </strong>
                                                    </div>
                                                    <div>
                                                        <span>
                                                            Tỉ lệ cược:{" "}
                                                        </span>
                                                        <strong>
                                                            {
                                                                this.state.cart[
                                                                    key
                                                                ].odds
                                                            }
                                                        </strong>
                                                    </div>
                                                </div>
                                                <div className="cart__item-balance">
                                                    <Input
                                                        // format="float"
                                                        // decimal={2}
                                                        min={1}
                                                        onInput={(
                                                            event,
                                                            value
                                                        ) =>
                                                            this.setBalanceCartItem(
                                                                event,
                                                                value,
                                                                this.state.cart[
                                                                    key
                                                                ]
                                                            )
                                                        }
                                                        // suffix={" $"}
                                                        type="number"
                                                        pattern="[0-9]*"
                                                        inputMode="numeric"
                                                        name={"balance_" + key}
                                                        placeholder="..."
                                                        value={
                                                            this.state.cart[key]
                                                                .balance
                                                        }
                                                    ></Input>
                                                </div>
                                                <div className="cart__item-action">
                                                    <Icon
                                                        type="fas"
                                                        className="fa-xmark"
                                                        onClick={() =>
                                                            this.removeCartItem(
                                                                this.state.cart[
                                                                    key
                                                                ]
                                                            )
                                                        }
                                                    ></Icon>
                                                </div>
                                            </div>
                                        );
                                    })
                                ) : (
                                    <NoData></NoData>
                                )}
                            </div>
                        </div>
                    )}
                </React.Fragment>
            </React.Fragment>
        );
    }
}

export default withRouter(React.memo(GameThreeDiceOneMin));
