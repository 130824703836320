import Func from "@/app/comFunc/Func";
import React from "react";
import BallResult from "../comBallResult/BallResult";
import "./ball_counter.scss";

class BallCounter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            counter: null,
        };
    }

    componentDidMount() {
        this.running = setInterval(() => {
            this.setState({
                ...this.state,
                counter: Func.randomBall(),
            });
        }, 100);
    }

    componentWillUnmount = () => {
        clearInterval(this.running);
    };

    render() {
        return (
            this.state.counter && (
                <BallResult
                    array={this.state.counter}
                    active={true}
                ></BallResult>
            )
        );
    }
}

export default BallCounter;
