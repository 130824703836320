import "./const.scss";
import Promotion from "@/screens/comPromotion/Promotion";
import Notification from "@/screens/comNotification/Notification";
import Account from "@/screens/comAccount/Account";
import Recharge from "@/screens/comAccount/comRecharge/Recharge";
import Withdraw from "@/screens/comAccount/comWithdraw/Withdraw";
import Bank from "@/screens/comAccount/comBank/Bank";
import Team from "@/screens/comAccount/comTeam/Team";
import Transaction from "@/screens/comAccount/comTransaction/Transaction";
import Bet from "@/screens/comAccount/comBet/Bet";
import RechargeHistory from "@/screens/comAccount/comRecharge/comRechargeHistory/RechargeHistory";
import WithdrawHistory from "@/screens/comAccount/comWithdraw/comWithdrawHistory/WithdrawHistory";
// import GameFiveBallThreeMin from '@/screens/comGame/GameFiveBallThreeMin';
import GameThreeDiceFiveMin from "@/screens/comGame/GameThreeDiceFiveMin";
import GameThreeDiceOneMin from "@/screens/comGame/GameThreeDiceOneMin";
import Home from "@/screens/comHome/Home";
import Login from "@/screens/comAuth/Login";
import Register from "@/screens/comAuth/Register";
import Trend from "@/screens/comTrend/Trend";
import Result from "@/screens/comResult/Result";
import Password from "@/screens/comAccount/comPassword/Password";
import ResultGameThreeDiceFiveMin from "@/screens/comResult/ResultGameThreeDiceFiveMin";
import ResultGameThreeDiceOneMin from "@/screens/comResult/ResultGameThreeDiceOneMin";
import PasswordWithdraw from "@/screens/comAccount/comPasswordWithdraw/PasswordWithdraw";
import AboutUs from "@/screens/comAboutUs/AboutUs";
import GameThreeDiceTenMin from "@/screens/comGame/GameThreeDiceTenMin";
import ResultGameThreeDiceThreeMin from "@/screens/comResult/ResultGameThreeDiceThreeMin";
import ResultGameThreeDiceTenMin from "@/screens/comResult/ResultGameThreeDiceTenMin";
import GameThreeDiceThreeMin from "@/screens/comGame/GameThreeDiceThreeMin";

export const Router = [
    {
        icon: "fa-house-chimney",
        name: "home",
        path: "trang-chu",
        component: Home,
        redirect: null,
        text: "Trang chủ",
        inMenu: true,
        showChildren: false,
        private: false,
        childrens: null,
    },
    {
        icon: null,
        name: "login",
        path: "dang-nhap",
        component: Login,
        redirect: null,
        text: "Đăng nhập tài khoản",
        inMenu: false,
        showChildren: false,
        private: false,
        childrens: null,
    },
    {
        icon: null,
        name: "register",
        path: "dang-ky",
        component: Register,
        redirect: null,
        text: "Đăng ký tài khoản",
        inMenu: false,
        showChildren: false,
        private: false,
        childrens: null,
    },
    {
        icon: null,
        name: "game",
        path: "tro-choi",
        component: null,
        redirect: "GameThreeDiceFiveMin",
        text: "Trò chơi",
        inMenu: false,
        showChildren: false,
        private: true,
        childrens: [
            {
                icon: null,
                name: "GameThreeDiceTenMin",
                path: "GameThreeDiceTenMin",
                component: GameThreeDiceTenMin,
                redirect: null,
                text: "Xổ số 10phút",
                inMenu: false,
                showChildren: false,
                private: true,
                childrens: null,
            },
            {
                icon: null,
                name: "GameThreeDiceFiveMin",
                path: "GameThreeDiceFiveMin",
                component: GameThreeDiceFiveMin,
                redirect: null,
                text: "Xổ số 5phút",
                inMenu: false,
                showChildren: false,
                private: true,
                childrens: null,
            },
            {
                icon: null,
                name: "GameThreeDiceThreeMin",
                path: "GameThreeDiceThreeMin",
                component: GameThreeDiceThreeMin,
                redirect: null,
                text: "Xổ số 3phút",
                inMenu: false,
                showChildren: false,
                private: true,
                childrens: null,
            },
            {
                icon: null,
                name: "GameThreeDiceOneMin",
                path: "GameThreeDiceOneMin",
                component: GameThreeDiceOneMin,
                redirect: null,
                text: "Xổ số 1phút",
                inMenu: false,
                showChildren: false,
                private: true,
                childrens: null,
            },
        ],
    },
    {
        icon: null,
        name: "trend",
        path: "xu-huong",
        component: Trend,
        redirect: null,
        text: "Xu hướng",
        inMenu: false,
        showChildren: false,
        private: true,
        childrens: null,
    },
    {
        icon: null,
        name: "result",
        path: "ket-qua",
        component: Result,
        redirect: null,
        text: "Kết quả",
        inMenu: false,
        showChildren: false,
        private: true,
        childrens: [
            {
                icon: null,
                name: "GameThreeDiceTenMin",
                path: "GameThreeDiceTenMin",
                component: ResultGameThreeDiceTenMin,
                redirect: null,
                text: "Xổ số 1 phút",
                inMenu: false,
                showChildren: false,
                private: true,
                childrens: null,
            },
            {
                icon: null,
                name: "GameThreeDiceFiveMin",
                path: "GameThreeDiceFiveMin",
                component: ResultGameThreeDiceFiveMin,
                redirect: null,
                text: "Xổ số 5phút",
                inMenu: false,
                showChildren: false,
                private: true,
                childrens: null,
            },
            {
                icon: null,
                name: "GameThreeDiceThreeMin",
                path: "GameThreeDiceThreeMin",
                component: ResultGameThreeDiceThreeMin,
                redirect: null,
                text: "Xổ số 3phút",
                inMenu: false,
                showChildren: false,
                private: true,
                childrens: null,
            },
            {
                icon: null,
                name: "GameThreeDiceOneMin",
                path: "GameThreeDiceOneMin",
                component: ResultGameThreeDiceOneMin,
                redirect: null,
                text: "Xổ số 1phút",
                inMenu: false,
                showChildren: false,
                private: true,
                childrens: null,
            },
        ],
    },
    {
        icon: null,
        name: "promotion",
        path: "khuyen-mai",
        component: Promotion,
        redirect: null,
        text: "Khuyến mại",
        inMenu: false,
        showChildren: false,
        private: false,
        childrens: null,
    },
    {
        icon: null,
        name: "notification",
        path: "thong-bao",
        component: Notification,
        redirect: null,
        text: "Thông báo",
        inMenu: false,
        showChildren: false,
        private: true,
        childrens: null,
    },
    {
        icon: null,
        name: "about_us",
        path: "ve-chung-toi",
        component: AboutUs,
        redirect: null,
        text: "Về chúng tôi",
        inMenu: false,
        showChildren: false,
        private: true,
        childrens: null,
    },
    {
        icon: null,
        name: "account",
        path: "tai-khoan",
        component: Account,
        redirect: null,
        text: "Trung tâm thành viên",
        inMenu: false,
        showChildren: false,
        private: true,
        childrens: [
            {
                icon: null,
                name: "recharge",
                path: "nap-tien",
                component: Recharge,
                redirect: null,
                text: "Nạp tiền",
                inMenu: false,
                showChildren: false,
                private: true,
                childrens: [
                    {
                        icon: null,
                        name: "history",
                        path: "lish-su",
                        component: RechargeHistory,
                        redirect: null,
                        text: "Lịch sử nạp tiền",
                        inMenu: false,
                        showChildren: false,
                        private: true,
                        childrens: null,
                    },
                ],
            },
            {
                icon: null,
                name: "withdraw",
                path: "rut-tien",
                component: Withdraw,
                redirect: null,
                text: "Rút tiền",
                inMenu: false,
                showChildren: false,
                private: true,
                childrens: [
                    {
                        icon: null,
                        name: "history",
                        path: "lish-su",
                        component: WithdrawHistory,
                        redirect: null,
                        text: "Lịch sử rút tiền",
                        inMenu: false,
                        showChildren: false,
                        private: true,
                        childrens: null,
                    },
                ],
            },
            {
                icon: null,
                name: "bank",
                path: "ngan-hang",
                component: Bank,
                redirect: null,
                text: "Tài khoản ngân hàng",
                inMenu: false,
                showChildren: false,
                private: true,
                childrens: null,
            },
            {
                icon: null,
                name: "transaction",
                path: "bien-dong-so-du",
                component: Transaction,
                redirect: null,
                text: "Biến động số dư",
                inMenu: false,
                showChildren: false,
                private: true,
                childrens: null,
            },
            {
                icon: null,
                name: "bet",
                path: "lich-su-cuoc",
                component: Bet,
                redirect: null,
                text: "Lịch sử đặt cược",
                inMenu: false,
                showChildren: false,
                private: true,
                childrens: null,
            },
            {
                icon: null,
                name: "team",
                path: "doi-nhom",
                component: Team,
                redirect: null,
                text: "Đội của tôi",
                inMenu: false,
                showChildren: false,
                private: true,
                childrens: null,
            },
            {
                icon: null,
                name: "password",
                path: "doi-mat-khau",
                component: Password,
                redirect: null,
                text: "Đổi mật khẩu",
                inMenu: false,
                showChildren: false,
                private: true,
                childrens: null,
            },
            {
                icon: null,
                name: "password_withdraw",
                path: "doi-mat-khau-rut-tien",
                component: PasswordWithdraw,
                redirect: null,
                text: "Đổi mật khẩu rút tiền",
                inMenu: false,
                showChildren: false,
                private: true,
                childrens: null,
            },
        ],
    },
];
export const Api = {
    auth: {
        login: { path: "/auth/client/login", method: "POST" },
        logout: { path: "/auth/client/logout", method: "POST" },
        check: { path: "/auth/client/check", method: "POST" },
        register: { path: "/auth/client/register", method: "POST" },
    },
    client: {
        mACL: {
            customer: {
                password: {
                    path: "/client/m-acl/customer/:customer/password",
                    method: "POST",
                },
                password_withdraw: {
                    path: "/client/m-acl/customer/:customer/password_withdraw",
                    method: "POST",
                },
                check_password_withdraw: {
                    path: "/client/m-acl/customer/:customer/check_password_withdraw",
                    method: "POST",
                },
                change_password_withdraw: {
                    path: "/client/m-acl/customer/:customer/change_password_withdraw",
                    method: "POST",
                },
                info: {
                    path: "/client/m-acl/customer/:customer/info",
                    method: "POST",
                },
                edit: {
                    path: "/client/m-acl/customer/:customer/edit",
                    method: "GET",
                },
                update: {
                    path: "/client/m-acl/customer/:customer",
                    method: "PUT",
                },
            },
            recharge: {
                index: { path: "/client/m-acl/recharge", method: "GET" },
                create: {
                    path: "/client/m-acl/recharge/create",
                    method: "GET",
                },
                store: { path: "/client/m-acl/recharge", method: "POST" },
            },
            withdraw: {
                index: { path: "/client/m-acl/withdraw", method: "GET" },
                create: {
                    path: "/client/m-acl/withdraw/create",
                    method: "GET",
                },
                store: { path: "/client/m-acl/withdraw", method: "POST" },
            },
            transaction: {
                index: { path: "/client/m-acl/transaction", method: "GET" },
            },
        },
        mGame: {
            bet: {
                index: { path: "/client/m-game/bet", method: "GET" },
            },
            result: {
                index: { path: "/client/m-game/result", method: "GET" },
                game: { path: "/client/m-game/result/:game", method: "GET" },
            },
            gameThreeDiceFiveMin: {
                load: {
                    path: "/client/m-game/gameThreeDiceFiveMin/load",
                    method: "POST",
                },
                bet: {
                    path: "/client/m-game/gameThreeDiceFiveMin/bet",
                    method: "POST",
                },
            },
            gameThreeDiceOneMin: {
                load: {
                    path: "/client/m-game/gameThreeDiceOneMin/load",
                    method: "POST",
                },
                bet: {
                    path: "/client/m-game/gameThreeDiceOneMin/bet",
                    method: "POST",
                },
            },
            gameThreeDiceThreeMin: {
                load: {
                    path: "/client/m-game/gameThreeDiceThreeMin/load",
                    method: "POST",
                },
                bet: {
                    path: "/client/m-game/gameThreeDiceThreeMin/bet",
                    method: "POST",
                },
            },
            gameThreeDiceTenMin: {
                load: {
                    path: "/client/m-game/gameThreeDiceTenMin/load",
                    method: "POST",
                },
                bet: {
                    path: "/client/m-game/gameThreeDiceTenMin/bet",
                    method: "POST",
                },
            },
        },
    },
};
const Const = {
    chips: [
        { key: 1, value: 100000, name: "100k" },
        { key: 2, value: 200000, name: "200k" },
        { key: 3, value: 500000, name: "500k" },
        { key: 4, value: 1000000, name: "1M" },
        { key: 5, value: 2000000, name: "2M" },
        { key: 6, value: 5000000, name: "5M" },
        { key: 7, value: 10000000, name: "10M" },
    ],
    filterAmount: [
        { value: 30, label: "30 bản ghi" },
        { value: 50, label: "50 bản ghi" },
        { value: 100, label: "100 bản ghi" },
        { value: 300, label: "300 bản ghi" },
        { value: 500, label: "500 bản ghi" },
        { value: 800, label: "800 bản ghi" },
        { value: 1000, label: "1000 bản ghi" },
        { value: 2000, label: "2000 bản ghi" },
    ],
    filterSort: [
        { value: 1, label: "Giảm dần thời gian tạo" },
        { value: 2, label: "Tăng dần thời gian tạo" },
        { value: 3, label: "Giảm dần thời cập nhật" },
        { value: 4, label: "Tăng dần thời cập nhật" },
        { value: 5, label: "(A-Z) theo tên" },
        { value: 6, label: "(Z-A) theo tên" },
    ],
    mBase: {
        banks_status: [
            { value: 1, label: "Mở " },
            { value: 2, label: "Đóng" },
        ],
    },
    mACL: {
        status: [
            { value: 1, label: "Hoạt động" },
            { value: 2, label: "Bị khóa" },
        ],
        gender: [
            { value: 1, label: "Nam" },
            { value: 2, label: "Nữ" },
            { value: 3, label: "Khác" },
        ],
        rechare_status: [
            { value: 1, label: "Đã chuyển khoản" },
            { value: 2, label: "Không chuyển khoản" },
            { value: 3, label: "Đang chờ" },
        ],
        rechare_process: [
            { value: 1, label: "Đã xử lý" },
            { value: 2, label: "Chưa xử lý" },
        ],
        transactions_type: [
            { value: 1, label: "Nạp tiền" },
            { value: 2, label: "Rút tiền" },
            { value: 3, label: "Đặt cược" },
            { value: 4, label: "Admin Thu hồi" },
            { value: 5, label: "Admin Cộng tiền" },
            { value: 6, label: "Thắng cược" },
            { value: 7, label: "Khuyến mại" },
            { value: 8, label: "Hoàn tiền rút" },
        ],
    },
    mGame: {
        ball_result: [
            { bom: 1, label: "Hàng vạn" },
            { bom: 2, label: "Hàng ngàn" },
            { bom: 3, label: "Hàng trăm" },
            { bom: 4, label: "Hàng chục" },
            { bom: 5, label: "Hàng đơn vị" },
        ],
        ball: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
        dice_result: [
            { bom: 1, label: "Xúc xắc thứ 1" },
            { bom: 2, label: "Xúc xắc thứ 2" },
            { bom: 3, label: "Xúc xắc thứ 3" },
        ],
        dice: [1, 2, 3, 4, 5, 6],
    },
};
export default Const;
