import React from "react";
import "./ball_result.scss";

class BallResult extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            counter1: null,
            counter2: null,
            counter3: null,
            pushBom1: false,
            pushBom2: false,
            pushBom3: false,
        };
    }

    componentDidMount() {
        this.running = setInterval(() => {
            this.setState({
                ...this.state,
                counter1: Math.floor(Math.random() * 10),
                counter2: Math.floor(Math.random() * 10),
                counter3: Math.floor(Math.random() * 10),
            });
        }, 100);
        if (this.props.effect) {
            this.setState({
                ...this.state,
                pushBom1: true,
                pushBom2: true,
                pushBom3: true,
            });
            // this.pushBom3 = setTimeout(() => {
            //     this.setState({
            //         ...this.state,
            //         pushBom3: true,
            //     });
            // }, 1000);
            // this.pushBom2 = setTimeout(() => {
            //     this.setState({
            //         ...this.state,
            //         pushBom2: true,
            //     });
            // }, 500);
            // this.pushBom1 = setTimeout(() => {
            //     this.setState({
            //         ...this.state,
            //         pushBom1: true,
            //     });
            // }, 0);
        }
    }

    componentWillUnmount = () => {
        clearInterval(this.running);
        clearTimeout(this.pushBom3);
        clearTimeout(this.pushBom2);
        clearTimeout(this.pushBom1);
    };

    render() {
        return !this.props.array || !(this.props.array instanceof Array) ? (
            <div
                className={
                    "ball-result" +
                    (this.props.small === true ? " ball-result__small" : "")
                }
            >
                <div>
                    {["?", "?", "?"].map((item, index) => {
                        return (
                            <span
                                className={
                                    (this.props.active === true
                                        ? " ball-result__active"
                                        : "") +
                                    (this.props.final === true
                                        ? " ball-result__final"
                                        : "")
                                }
                                key={index}
                            >
                                {item}
                            </span>
                        );
                    })}
                </div>
            </div>
        ) : (
            <div
                className={
                    "ball-result" +
                    (this.props.small === true ? " ball-result__small" : "")
                }
            >
                <div>
                    {this.props.effect ? (
                        <React.Fragment>
                            {this.state.pushBom1 ? (
                                <span className="ball-result__final">
                                    {this.props.array[0]}
                                </span>
                            ) : (
                                <span className="ball-result__active">
                                    {this.state.counter1}
                                </span>
                            )}
                            {this.state.pushBom2 ? (
                                <span className="ball-result__final">
                                    {this.props.array[1]}
                                </span>
                            ) : (
                                <span className="ball-result__active">
                                    {this.state.counter2}
                                </span>
                            )}
                            {this.state.pushBom3 ? (
                                <span className="ball-result__final">
                                    {this.props.array[2]}
                                </span>
                            ) : (
                                <span className="ball-result__active">
                                    {this.state.counter3}
                                </span>
                            )}
                        </React.Fragment>
                    ) : (
                        this.props.array.map((item, index) => {
                            return (
                                <span
                                    className={
                                        (this.props.success === true
                                            ? " ball-result__success"
                                            : "") +
                                        (this.props.active === true
                                            ? " ball-result__active"
                                            : "") +
                                        (this.props.final === true
                                            ? " ball-result__final"
                                            : "")
                                    }
                                    key={index}
                                >
                                    {item}
                                </span>
                            );
                        })
                    )}
                </div>
            </div>
        );
    }
}

export default BallResult;
