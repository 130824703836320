import React from "react";
import "./about_us.scss";
import withRouter from "@/app/comRouter/withRouter";
import Header from "@/layouts/comHeader/Header";
import Func from "@/app/comFunc/Func";

class AboutUs extends React.Component {
    constructor(props) {
        super(props);
        window.AboutUs = this;
        this.state = {};
    }
    _isMounted = false;
    componentDidMount = () => {
        this._isMounted = true;
    };
    componentWillUnmount() {
        this._isMounted = false;
    }
    render() {
        return (
            <React.Fragment>
                <Header heading={Func.getRoute("about_us", "text")}></Header>
                <div className="about_us">
                    <h3>1. Tiêu chuẩn cấp độ:</h3>
                    <p>
                        Thành viên nạp tiền theo đúng tiêu chuẩn sẽ nhận được
                        cấp độ <strong>VIP</strong> sau đây:
                    </p>
                    <ul>
                        <li>
                            <strong>VIP 1</strong>: Nạp 1000 USD
                        </li>
                        <li>
                            <strong>VIP 2</strong>: Nạp 2500 USD
                        </li>
                        <li>
                            <strong>VIP 3</strong>: Nạp 5000 USD
                        </li>
                        <li>
                            <strong>VIP 4</strong>: Nạp 10000 USD
                        </li>
                        <li>
                            <strong>VIP 5</strong>: Nạp 20000 USD
                        </li>
                    </ul>
                    <h3>
                        2. Nâng cấp sự kiện theo cấp độ <strong>VIP</strong> để
                        nhận quyền và chiết khấu đặc biệt <strong>VIP</strong>{" "}
                        cấp độ 2 trở lên có thể bỏ qua, hoàn tiền 13% mỗi ngày,
                        chiết khấu rất nhiều.
                    </h3>
                    <p>
                        Chỉ cảm ơn khuyến mãi <strong>VIP</strong>:
                    </p>
                    <ul>
                        <li>
                            <strong>VIP 2</strong>: Hoàn tiền hàng ngày, nâng
                            cấp thành công sẽ được khuyến mãi{" "}
                            <span>2500 USD</span>
                        </li>
                        <li>
                            <strong>VIP 3</strong>: Hoàn tiền hàng ngày, nâng
                            cấp thành công sẽ được khuyến mãi{" "}
                            <span>5000 USD</span>
                        </li>
                        <li>
                            <strong>VIP 4</strong>: Nhận chức năng rút tiền tự
                            động, tham gia mọi trò chơi, nhận khuyến mãi{" "}
                            <span>10000 USD</span>
                        </li>
                        <li>
                            <strong>VIP 5</strong>: Hoàn tiền từng thời kỳ. Nhận
                            thưởng hàng tháng, thăng{" "}
                            <span>20000 USD</span>
                        </li>
                    </ul>
                    {/* <p>
                        Trang mạng của chúng tôi nghiêm cấm tất cả các hành vi
                        cá cược vi phạm luật cược quốc tế bao gồm: Hành vi đặt
                        đối cược <strong>TÀI XỈU CHẴN LẺ</strong> (hành vi trục
                        lợi), đặt cược xổ số không được cược quá 70% con số. Nếu
                        quý khách có hành vi vi phạm luật cược của trang chúng
                        tôi thì bắt buộc phải tuân theo nguyên tắc của nhà mạng,
                        làm theo yêu cầu của bộ phận kỹ thuật thông qua thông
                        báo của <span>CSKH</span> để được giải quyết vấn đề thực
                        tế.
                    </p>
                    <p>
                        Với <strong>TÀI XỈU</strong>, bạn sử dụng 3 viên xúc xắc
                        (hay còn gọi là xí ngầu). Mỗi viên xúc xắc có 6 mặt và
                        người chơi sẽ đặt cược kết quả sau khi nhà cái lắc xúc
                        xắc. Nếu kết quả trên tổng 3 mặt của xúc xắc là 3 đến 10
                        thì đó gọi là <strong>“Xỉu”</strong>, còn nếu là 11 đến
                        18 thì đó là <strong>“Tài”</strong>.
                    </p>
                    <p>
                        Với <strong>CHẴN LẺ</strong>, bạn sử dụng 3 viên xúc xắc
                        (hay còn gọi là xí ngầu). Mỗi viên xúc xắc có 6 mặt và
                        người chơi sẽ đặt cược kết quả sau khi nhà cái lắc xúc
                        xắc. Nếu kết quả trên tổng 3 mặt của xúc xắc chia hết
                        cho 2 thì đó gọi là <strong>“CHẴN”</strong>, còn nếu
                        tổng 3 mặt xúc xắc không chia hết cho 2 thì gọi đó là
                        <strong>"LẺ"</strong>.
                    </p> */}
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(React.memo(AboutUs));
