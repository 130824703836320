import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Const, { Router, Api } from "@/app/comConst/Const";
import axios from "axios";
import Cookies from "universal-cookie";
// import nprogress from 'nprogress';
// import 'nprogress/nprogress.css';
import moment from "moment";
import "moment/locale/vi";
import "./func.scss";
import React from "react";
import Label from "@/components/comLabel/Label";
import Countdown, { zeroPad } from "react-countdown";
import Text from "@/components/comText/Text";
import Icon from "@/components/comIcon/Icon";

const Func = {
    getRoute: (search, get = "path", params = null) => {
        let routes = Func.getRoutes(Router),
            result = routes.find((route) => {
                return route.key === search;
            });
        if (result && result[get]) {
            switch (get) {
                case "path":
                    result = "/" + result[get];
                    if (
                        result &&
                        typeof params === "object" &&
                        params !== null
                    ) {
                        result = Func.passParamsToPath(result, params);
                    }
                    return result;
                default:
                    return result[get];
            }
        }
        return get === "all" ? result : get === "component" ? null : null;
    },
    getRoutes: (routes, path = null, result = [], key = null) => {
        routes.forEach((route) => {
            let keyItem = key ? key + "." + route.name : route.name;
            let pathItem = path ? path + "/" + route.path : route.path;
            let redirectItem = route.redirect;
            if (route.redirect && route.childrens) {
                redirectItem = route.childrens.find(
                    (children) => children.name === route.redirect
                );
                redirectItem = redirectItem.path;
            }
            result.push({
                icon: route.icon,
                key: keyItem,
                path: pathItem,
                component: route.component,
                redirect: redirectItem,
                text: route.text,
                private: route.private,
            });
            route.childrens &&
                (result = Func.getRoutes(
                    route.childrens,
                    pathItem,
                    result,
                    keyItem
                ));
        });
        return result;
    },
    getBreadcrumb: (
        search,
        routes = Router.private,
        result = [],
        key = null,
        path = null
    ) => {
        routes.forEach((route) => {
            let keyItem = key ? key + "." + route.name : route.name;
            let pathItem = path ? path + "/" + route.path : route.path;
            if (
                search.split(".").includes(keyItem) ||
                search.split(".").includes(route.name)
            ) {
                result.push({
                    key: keyItem,
                    text: route.text,
                    link: "/" + pathItem,
                });
                route.childrens &&
                    (result = Func.getBreadcrumb(
                        search,
                        route.childrens,
                        result,
                        keyItem,
                        pathItem
                    ));
            }
        });
        return result;
    },
    env: (key) => {
        let result = process.env[key];
        result = result === "true" ? true : result === "false" ? false : result;
        result =
            result === "null" || result === "NULL" || result === "Null"
                ? null
                : result;
        return result;
    },
    getStorage: (key) => {
        let result = localStorage.getItem(key);
        result = result === "true" ? true : result === "false" ? false : result;
        result =
            result === "null" || result === "NULL" || result === "Null"
                ? null
                : result;
        return result;
    },
    setStorage: (object, callback = null) => {
        if (
            typeof object === "object" &&
            !Array.isArray(object) &&
            object !== null
        ) {
            Object.keys(object).forEach(function (key) {
                let value =
                    object[key] === "true"
                        ? true
                        : object[key] === "false"
                        ? false
                        : object[key];
                localStorage.setItem(key, value);
            });
            callback && callback();
            return true;
        }
        callback && callback();
        return false;
    },
    removeStorage: (...items) => {
        if (typeof items === "object" && items !== null) {
            Object.keys(items).forEach(function (key) {
                localStorage.removeItem(items[key]);
            });
            return true;
        }
        return false;
    },
    getCookie: (key) => {
        const cookies = new Cookies();
        let result = cookies.get(key, { path: "/" });
        result = result === "true" ? true : result === "false" ? false : result;
        return result;
    },
    setCookie: (object) => {
        if (
            typeof object === "object" &&
            !Array.isArray(object) &&
            object !== null
        ) {
            const cookies = new Cookies();
            Object.keys(object).forEach(function (key) {
                cookies.get(key) && cookies.remove(key);
                let value =
                    object[key] === "true"
                        ? true
                        : object[key] === "false"
                        ? false
                        : object[key];
                cookies.set(key, value, {
                    path: "/",
                    secure: true,
                    sameSite: "None",
                });
            });
            return true;
        }
        return false;
    },
    removeCookie: (...items) => {
        if (typeof items === "object" && items !== null) {
            const cookies = new Cookies();
            Object.keys(items).forEach(function (key) {
                cookies.remove(items[key]);
            });
            return true;
        }
        return false;
    },
    send: (
        url,
        method = "GET",
        data = null,
        thenCallback,
        catchCallback,
        is_sending = true,
        contentType = "application/json"
    ) => {
        if (is_sending) {
            Func.setStorage({ is_sending: true });
            // nprogress.done();
            // nprogress.start();
        }
        if (method !== "GET" && method !== "POST") {
            if (data instanceof FormData) {
                data.append("_method", method);
            } else if (data instanceof Object) {
                data._method = method;
            } else if (data === null) {
                data = {
                    _method: method,
                };
            }
        }
        axios
            .request({
                url: url,
                method: method === "GET" ? "GET" : "POST",
                data: data,
                params: method === "GET" ? data : null,
                headers: {
                    "Content-Type": contentType,
                    token: Func.getStorage("token") || null,
                },
                json: contentType === "application/json" ? true : false,
            })
            .then((res) => {
                if (is_sending) {
                    Func.setStorage({ is_sending: false });
                    // nprogress.done();
                }
                if (
                    res.data.data &&
                    res.data.data.token &&
                    res.data.data.token_expired
                ) {
                    Func.setStorage({
                        token: res.data.data.token,
                        token_expired: res.data.data.token_expired,
                    });
                    res.data.data.token_expired !== true && thenCallback(res);
                } else {
                    thenCallback(res);
                }
            })
            .catch((res) => {
                if (is_sending) {
                    Func.setStorage({ is_sending: false });
                    // nprogress.done();
                }
                if (
                    res.response &&
                    res.response.data &&
                    res.response.data.code &&
                    res.response.data.code === 403 &&
                    res.response.data.status &&
                    res.response.data.status === "Forbidden"
                ) {
                    Func.setStorage({
                        id: null,
                        username: null,
                        balance: null,
                        code: null,
                        avatar_default: null,
                        token_expired: true,
                    });
                    console.clear();
                    Func.alertError("Tài khoản của bạn đã bị khóa!");
                }
                if (
                    res.response &&
                    res.response.data &&
                    res.response.data.code &&
                    res.response.data.code === 401 &&
                    res.response.data.status &&
                    res.response.data.status === "Unauthorized"
                ) {
                    localStorage.clear();
                    console.clear();
                }
                catchCallback(res);
            });
    },
    check: () => {
        let result = false;
        Func.send(
            Func.getApi("auth.check"),
            Func.getApi("auth.check", "method"),
            null,
            (res) => {
                if (res.data.data) {
                    Func.setStorage({
                        token: res.data.data.token,
                        token_expired: res.data.data.token_expired,
                    });
                    result = true;
                } else {
                    Func.setStorage({ token_expired: true });
                    result = false;
                }
            },
            (res) => {
                Func.setStorage({ token_expired: true });
                result = false;
            },
            false
        );
        return result;
    },
    notify: (message) => {
        return message && toast(message);
    },
    notifySuccess: (message) => {
        return message && toast.success(message);
    },
    notifyWarning: (message) => {
        return message && toast.warning(message);
    },
    notifyError: (message) => {
        return message && toast.error(message);
    },
    notifyDefault: (message) => {
        return message && toast.default(message);
    },
    notifyInfo: (message) => {
        return message && toast.info(message);
    },
    alert: (message, confirm = false, html = false) => {
        const MySwal = withReactContent(Swal);
        MySwal.fire({
            target: "#root",
            title: message,
            text: null,
            html: html,
            showCancelButton: true,
            showConfirmButton: confirm,
            confirmButtonColor: "#23a981",
            confirmButtonText: "Đồng ý",
            cancelButtonText: "Đóng",
        });
    },
    alertSuccess: (message, confirm = false, html = false) => {
        const MySwal = withReactContent(Swal);
        MySwal.fire({
            target: "#root",
            icon: "success",
            title: message,
            text: null,
            html: html,
            showCancelButton: true,
            showConfirmButton: confirm,
            confirmButtonColor: "#23a981",
            confirmButtonText: "Đồng ý",
            cancelButtonText: "Đóng",
        });
    },
    alertWarning: (message, confirm = false, html = false) => {
        const MySwal = withReactContent(Swal);
        MySwal.fire({
            target: "#root",
            icon: "warning",
            title: message,
            text: null,
            html: html,
            showCancelButton: true,
            showConfirmButton: confirm,
            confirmButtonColor: "#23a981",
            confirmButtonText: "Đồng ý",
            cancelButtonText: "Đóng",
        });
    },
    alertError: (message, confirm = false, html = false) => {
        const MySwal = withReactContent(Swal);
        MySwal.fire({
            target: "#root",
            icon: "error",
            title: message,
            text: null,
            html: html,
            showCancelButton: true,
            showConfirmButton: confirm,
            confirmButtonColor: "#23a981",
            confirmButtonText: "Đồng ý",
            cancelButtonText: "Đóng",
        });
    },
    alertInfo: (message, confirm = false, html = false) => {
        const MySwal = withReactContent(Swal);
        MySwal.fire({
            target: "#root",
            icon: "info",
            title: message,
            text: null,
            html: html,
            showCancelButton: true,
            showConfirmButton: confirm,
            confirmButtonColor: "#23a981",
            confirmButtonText: "Đồng ý",
            cancelButtonText: "Đóng",
        });
    },
    confirmAlert: (message, callback, html = false) => {
        const MySwal = withReactContent(Swal);
        MySwal.fire({
            target: "#root",
            icon: "success",
            title: message,
            text: null,
            html: html,
            showCancelButton: true,
            confirmButtonColor: "#23a981",
            confirmButtonText: "Đồng ý",
            cancelButtonText: "Đóng",
        }).then((confirmed) => {
            callback(confirmed && confirmed.value === true);
        });
    },
    htmlDecode: (input) => {
        let element = document.createElement("div");
        element.innerHTML = input;
        return element.childNodes.length === 0
            ? ""
            : element.childNodes[0].nodeValue;
    },
    stripHtml: (html) => {
        let element = document.createElement("div");
        element.innerHTML = html;
        return element.textContent || element.innerText || "";
    },
    checkObjectEmpty: (object) => {
        return (
            object &&
            Object.keys(object).length === 0 &&
            Object.getPrototypeOf(object) === Object.prototype
        );
    },
    handleErrors: (res, component) => {
        if (res.response && res.response.data) {
            if (res.response.data.errors) {
                component.setState({
                    ...component.state,
                    errors: res.response.data.errors,
                });
                let errors = res.response.data.errors;
                Object.keys(errors).forEach(function (key) {
                    if (errors[key] instanceof String) {
                        Func.notifyError(errors[key]);
                    } else if (
                        typeof errors[key] === "object" &&
                        errors[key] !== null
                    ) {
                        errors[key].forEach(function (error) {
                            Func.notifyError(error);
                        });
                    }
                });
            } else if (res.response.data.message) {
                (typeof res.response.data.message === "string" ||
                    res.response.data.message instanceof String) &&
                    Func.notifyError(res.response.data.message);
            }
        } else if (res.data && res.data.message) {
            (typeof res.data.message === "string" ||
                res.data.message instanceof String) &&
                Func.notifyWarning(res.data.message);
        } else if (res.message) {
            (typeof res.message === "string" ||
                res.message instanceof String) &&
                Func.notifyError(res.message);
        }
    },
    getParamRoute: (param = null, component = null) => {
        if (param && component && component.props && component.props.params) {
            return component.props.params[param] || null;
        }
        return null;
    },
    getParamUrl: (param, url = window.location.search) => {
        let params = new URLSearchParams(url);
        return params.get(param);
    },
    passParamsToPath: (path, params) => {
        if (params) {
            Object.keys(params).forEach(function (key) {
                let regexp = new RegExp("(:" + key + ")", "g");
                path = path.replace(regexp, params[key]);
            });
        }
        return path;
    },
    getApi: (route, type = "path", params, debug = false) => {
        if (route && ["path", "method"].includes(type)) {
            route += "." + type;
            route = route.split(".");
            let prefix =
                Func.env("REACT_APP_MODE_PRODUCTION") === true
                    ? Func.env("REACT_APP_SERVER_PRODUCTION_URL")
                    : Func.env("REACT_APP_SERVER_LOCAL_URL");
            let result = route.reduce((obj, key) => obj[key], Api);
            result = type === "path" ? prefix + result : result;
            if (result && typeof params === "object" && params !== null) {
                result = Func.passParamsToPath(result, params);
            }
            return result;
        }
        return null;
    },
    getImage: (path = null) => {
        let prefix =
            Func.env("REACT_APP_MODE_PRODUCTION") === true
                ? Func.env("REACT_APP_SERVER_PRODUCTION_URL")
                : Func.env("REACT_APP_SERVER_LOCAL_URL");
        return path ? prefix + path : null;
    },
    checkSidebar: () => {
        let check = Func.getStorage("hide_sidebar");
        if (check && check === true) {
            return false;
        }
        return true;
    },
    collapseSidebar: () => {
        let main = document.getElementById("root");
        if (Func.checkSidebar()) {
            Func.setStorage({ hide_sidebar: true });
            main.classList.add("main__full");
        } else {
            Func.setStorage({ hide_sidebar: false });
            main.classList.remove("main__full");
        }
    },
    handleSidebar: () => {
        let sidebar_bg = document.getElementById("sidebar__bg");
        if (sidebar_bg) {
            let checkDisplay = window
                .getComputedStyle(sidebar_bg)
                .getPropertyValue("display");
            checkDisplay !== "none" && Func.collapseSidebar();
        }
    },
    isInteger: (value) => {
        if (typeof value == "number" && !isNaN(value)) {
            if (Number.isInteger(value)) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    },
    isFloat: (value) => {
        if (typeof value == "number" && !isNaN(value)) {
            if (Number.isInteger(value)) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    },
    isNumeric: (value) => {
        return /^-?\d+$/.test(value);
    },
    countDecimals: (value) => {
        if (Math.floor(value) === value) return 0;
        return value?.toString().split(".")[1].length || 0;
    },
    filterNumeric: (value) => {
        return value
            ? parseFloat(value?.toString().replace(/[^0-9.]/g, ""))
            : null;
    },
    formatInteger: (value) => {
        value = value?.toString() || "";
        let value_real = value ? Func.filterNumeric(value) : "";
        value_real = value_real?.toString();
        return parseFloat(value_real) > 0
            ? value
                  ?.toString()
                  .replace(/^0+/, "")
                  .replace(/\D/g, "")
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
            : "";
    },
    formatFloat: (value = null, decimal = 2) => {
        value = value ? value?.toString().replace(/[^0-9.]/g, "") || "" : null;
        if (value !== "null" && value !== null) {
            if (/^.+\.0*$/g.test(value?.toString() || "") || !value)
                return value;
            value = value?.toString() || "";
            let value_real = value.replace(/[^0-9.]/g, "");
            let count_decimal = Func.countDecimals(
                Func.filterNumeric(value_real)
            );
            value_real = Func.filterNumeric(value_real).toFixed(
                count_decimal > decimal ? decimal : count_decimal
            );
            let arr = value_real?.toString().match(/^(\d+)((?:\.\d+)?)$/);
            return (
                (Func.filterNumeric(value) < 0 ? "-" : "") +
                (
                    arr[1].replace(/(\d)(?=(?:\d{3})+$)/g, "$1,") + arr[2]
                ).replace(/^0[^.]+/, "")
            );
        }
        return null;
    },
    formatVN: (string) => {
        string = string.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
        string = string.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
        string = string.replace(/ì|í|ị|ỉ|ĩ/g, "i");
        string = string.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
        string = string.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
        string = string.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
        string = string.replace(/đ/g, "d");
        string = string.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
        string = string.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
        string = string.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
        string = string.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
        string = string.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
        string = string.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
        string = string.replace(/Đ/g, "D");
        return string;
    },
    formatAlphabet: (string, caseMode = null, space = true) => {
        let result = Func.formatVN(string);
        if (space) {
            result = result.replace(/  +/g, " ");
        } else {
            result = result.replace(/ +/g, "");
        }
        return caseMode
            ? caseMode === 1
                ? result.toLowerCase().replace(/[^a-z\s]/g, "")
                : caseMode === 2
                ? result.toUpperCase().replace(/[^A-Z\s]/g, "")
                : result.replace(/[^A-Za-z\s]/g, "")
            : result.replace(/[^A-Za-z\s]/g, "");
    },
    formatAlphadash: (string, caseMode = null, space = true) => {
        let result = Func.formatVN(string);
        if (space) {
            result = result.replace(/  +/g, " ");
        } else {
            result = result.replace(/ +/g, "");
        }
        return caseMode
            ? caseMode === 1
                ? result.toLowerCase().replace(/[^a-z0-9.\s]/g, "")
                : caseMode === 2
                ? result.toUpperCase().replace(/[^A-Z0-9.\s]/g, "")
                : result.replace(/[^A-Za-z0-9.\s]/g, "")
            : result.replace(/[^A-Za-z0-9.\s]/g, "");
    },
    formatSlug: (string, caseMode = null) => {
        let result = Func.formatVN(string);
        result = result.replace(/[^A-Za-z0-9-\s]/g, "");
        result = result.replace(/ /g, "-");
        result = result.replace(/--/g, "-");
        return caseMode
            ? caseMode === 1
                ? result.toLowerCase().replace(/[^a-z0-9-\s]/g, "")
                : caseMode === 2
                ? result.toUpperCase().replace(/[^A-Z0-9-\s]/g, "")
                : result.replace(/[^A-Za-z0-9-\s]/g, "")
            : result.replace(/[^A-Za-z0-9-\s]/g, "");
    },
    formatAlphabetVN: (string, caseMode = null, space = true) => {
        let result = string;
        if (space) {
            result = result.replace(/  +/g, " ");
        } else {
            result = result.replace(/ +/g, "");
        }
        return caseMode
            ? caseMode === 1
                ? result
                      .toLowerCase()
                      .replace(
                          /[^a-zàáạảãâầấậẩẫăằắặẳẵèéẹẻẽêềếệểễìíịỉĩòóọỏõôồốộổỗơờớợởỡùúụủũưừứựửữỳýỵỷỹđ\s]/g,
                          ""
                      )
                : caseMode === 2
                ? result
                      .toUpperCase()
                      .replace(
                          /[^A-ZÀÁẠẢÃÂẦẤẬẨẪĂẰẮẶẲẴÈÉẸẺẼÊỀẾỆỂỄÌÍỊỈĨÒÓỌỎÕÔỒỐỘỔỖƠỜỚỢỞỠÙÚỤỦŨƯỪỨỰỬỮỲÝỴỶỸĐ\s]/g,
                          ""
                      )
                : result.replace(
                      /[^A-Za-zàáạảãâầấậẩẫăằắặẳẵèéẹẻẽêềếệểễìíịỉĩòóọỏõôồốộổỗơờớợởỡùúụủũưừứựửữỳýỵỷỹđÀÁẠẢÃÂẦẤẬẨẪĂẰẮẶẲẴÈÉẸẺẼÊỀẾỆỂỄÌÍỊỈĨÒÓỌỎÕÔỒỐỘỔỖƠỜỚỢỞỠÙÚỤỦŨƯỪỨỰỬỮỲÝỴỶỸĐ\s]/g,
                      ""
                  )
            : result.replace(
                  /[^A-Za-zàáạảãâầấậẩẫăằắặẳẵèéẹẻẽêềếệểễìíịỉĩòóọỏõôồốộổỗơờớợởỡùúụủũưừứựửữỳýỵỷỹđÀÁẠẢÃÂẦẤẬẨẪĂẰẮẶẲẴÈÉẸẺẼÊỀẾỆỂỄÌÍỊỈĨÒÓỌỎÕÔỒỐỘỔỖƠỜỚỢỞỠÙÚỤỦŨƯỪỨỰỬỮỲÝỴỶỸĐ\s]/g,
                  ""
              );
    },
    formatAlphadashVN: (string, caseMode = null, space = true) => {
        let result = string;
        if (space) {
            result = result.replace(/  +/g, " ");
        } else {
            result = result.replace(/ +/g, "");
        }
        return caseMode
            ? caseMode === 1
                ? result
                      .toLowerCase()
                      .replace(
                          /[^a-zàáạảãâầấậẩẫăằắặẳẵèéẹẻẽêềếệểễìíịỉĩòóọỏõôồốộổỗơờớợởỡùúụủũưừứựửữỳýỵỷỹđ0-9\s]/g,
                          ""
                      )
                : caseMode === 2
                ? result
                      .toUpperCase()
                      .replace(
                          /[^A-ZÀÁẠẢÃÂẦẤẬẨẪĂẰẮẶẲẴÈÉẸẺẼÊỀẾỆỂỄÌÍỊỈĨÒÓỌỎÕÔỒỐỘỔỖƠỜỚỢỞỠÙÚỤỦŨƯỪỨỰỬỮỲÝỴỶỸĐ0-9\s]/g,
                          ""
                      )
                : result.replace(
                      /[^A-Za-zàáạảãâầấậẩẫăằắặẳẵèéẹẻẽêềếệểễìíịỉĩòóọỏõôồốộổỗơờớợởỡùúụủũưừứựửữỳýỵỷỹđÀÁẠẢÃÂẦẤẬẨẪĂẰẮẶẲẴÈÉẸẺẼÊỀẾỆỂỄÌÍỊỈĨÒÓỌỎÕÔỒỐỘỔỖƠỜỚỢỞỠÙÚỤỦŨƯỪỨỰỬỮỲÝỴỶỸĐ0-9\s]/g,
                      ""
                  )
            : result.replace(
                  /[^A-Za-zàáạảãâầấậẩẫăằắặẳẵèéẹẻẽêềếệểễìíịỉĩòóọỏõôồốộổỗơờớợởỡùúụủũưừứựửữỳýỵỷỹđÀÁẠẢÃÂẦẤẬẨẪĂẰẮẶẲẴÈÉẸẺẼÊỀẾỆỂỄÌÍỊỈĨÒÓỌỎÕÔỒỐỘỔỖƠỜỚỢỞỠÙÚỤỦŨƯỪỨỰỬỮỲÝỴỶỸĐ0-9\s]/g,
                  ""
              );
    },
    formatPhoneVN: (string, max = 10) => {
        return string.replace(/[^0-9]/g, "").slice(0, max);
    },
    formatBalance: (value = null, decimal = 2) => {
        return value ? Func.formatFloat(value, decimal) + " USD" : "0 USD";
    },
    formatBalanceShort: (value = null) => {
        value = Func.filterNumeric(value);
        let unit = undefined;
        if (value >= 1000000000000) {
            value = parseFloat(value / 1000000000000);
            let count_decimal = Func.countDecimals(value);
            value = value.toFixed(count_decimal > 2 ? 2 : count_decimal);
            unit = "T";
        } else if (value < 1000000000000 && value >= 1000000000) {
            value = parseFloat(value / 1000000000);
            let count_decimal = Func.countDecimals(value);
            value = value.toFixed(count_decimal > 2 ? 2 : count_decimal);
            unit = "B";
        } else if (value < 1000000000 && value >= 1000000) {
            value = parseFloat(value / 1000000);
            let count_decimal = Func.countDecimals(value);
            value = value.toFixed(count_decimal > 2 ? 2 : count_decimal);
            unit = "M";
        } else if (value < 1000000 && value >= 1000) {
            value = parseFloat(value / 1000);
            let count_decimal = Func.countDecimals(value);
            value = value.toFixed(count_decimal > 2 ? 2 : count_decimal);
            unit = "K";
        } else {
            return Func.formatBalance(value);
        }
        return parseFloat(value) + unit;
    },
    arraysToCombine: (array) => {
        let divisors = [];
        for (let i = array.length - 1; i >= 0; i--) {
            divisors[i] = divisors[i + 1]
                ? divisors[i + 1] * array[i + 1].length
                : 1;
        }

        function getPermutation(n, array) {
            let result = [],
                curArray;
            for (let i = 0; i < array.length; i++) {
                curArray = array[i];
                result.push(
                    curArray[Math.floor(n / divisors[i]) % curArray.length]
                );
            }
            return result;
        }

        let numPerms = array[0].length;
        for (let i = 1; i < array.length; i++) {
            numPerms *= array[i].length;
        }
        let combinations = [];
        for (let i = 0; i < numPerms; i++) {
            combinations.push(getPermutation(i, array));
        }
        return combinations;
    },
    formatDate(
        dateObject = null,
        option = null,
        format = "YYYY-MM-DD HH:mm:ss"
    ) {
        if (!dateObject) {
            return null;
        }
        dateObject = dateObject
            ? moment(dateObject).isValid()
                ? moment(dateObject)
                : moment(dateObject, format, true).isValid()
                ? moment(dateObject, format, true)
                : moment()
            : moment();
        let result = dateObject.locale("vi");
        switch (option) {
            case 1:
                result = result.format("ddd, DD \\T\\h\\á\n\\g MM YYYY");
                break;
            case 2:
                result = result.format("ddd, DD/MM/YYYY");
                break;
            case 3:
                result = result.format("ddd, DD/MM/YYYY \\(hh:mm A\\)");
                break;
            case 4:
                result = result.format("YYYY-MM-DD");
                break;
            case 5:
                result = result.format("DD-MM-YYYY \\(hh:mm A\\)");
                break;
            case 6:
                result = result.format("ddd, DD/MM/YYYY | hh:mm A");
                break;
            case 7:
                result = result.format("hh:mm:ss A");
                break;
            case 8:
                result = result.format("hh:mm:ssA");
                break;
            case 9:
                result = result.format("YYYY-MM-DD-HH-mm-ss");
                break;
            case 10:
                result = result.format("DD/MM/YYYY \\l\\ú\\c hh:mm A");
                break;
            case 11:
                result = result.format("ddd, DD/MM/YYYY \\l\\ú\\c hh:mm A");
                break;
            case 12:
                result = result.format("hh:mm A DD/MM/YYYY");
                break;
            case 13:
                result = result.format("hh:mm:ss A DD/MM/YYYY");
                break;
            case 14:
                result = result.format("DD/MM/YYYY hh:mm:ss A");
                break;
            case 15:
                result = result.format("DD/MM/YYYY");
                break;
            case 16:
                result = result.format("HH:mm:ss DD/MM/YYYY");
                break;
            default:
                result = result.format("DD/MM/YYYY HH:mm");
                break;
        }
        return result;
    },
    copy(value) {
        let temp = document.createElement("input");
        temp.setAttribute("value", value);
        document.body.appendChild(temp);
        temp.select();
        document.execCommand("copy");
        document.body.removeChild(temp);
        Func.notifySuccess("Đã sao chép !");
    },
    setValueInput(event, component) {
        let fieldName =
                event && event.target && event.target.name
                    ? event.target.name
                    : "",
            fieldValue =
                event && event.target && event.target.value
                    ? event.target.value
                    : "";
        if (!fieldName) {
            return fieldValue;
        } else {
            component.setState({
                ...component.state,
                [fieldName]: fieldValue,
                errors: {
                    ...component.state.errors,
                    [fieldName]: null,
                },
            });
            return fieldValue;
        }
    },
    setValueOption(selected, component, field = null) {
        if (!field) {
            return selected && selected.value ? selected.value : null;
        } else {
            component.setState({
                ...component.state,
                [field]: selected && selected.value ? selected.value : null,
                errors: {
                    ...component.state.errors,
                    [field]: "",
                },
            });
            return selected && selected.value ? selected.value : null;
        }
    },
    setValueDateSelect(value, component, field = null) {
        if (!field) {
            return false;
        } else {
            component.setState({
                ...component.state,
                [field]: value,
                errors: {
                    ...component.state.errors,
                    [field]: "",
                },
            });
            return true;
        }
    },
    setValueDateRangeSelect(
        values,
        component,
        fieldStart = null,
        fieldEnd = null
    ) {
        if (!fieldStart || !fieldEnd) {
            return false;
        } else {
            const [start, end] = values;
            component.setState({
                ...component.state,
                [fieldStart]: start,
                [fieldEnd]: end,
                errors: {
                    ...component.state.errors,
                    [fieldStart]: "",
                    [fieldEnd]: "",
                },
            });
            return true;
        }
    },
    randomNumber(min, max, decimalPlaces) {
        var rand = Math.random() * (max - min) + min;
        var power = Math.pow(10, decimalPlaces);
        return Math.floor(rand * power) / power;
    },
    ranBalance() {
        let run =
            parseInt(Func.randomNumber(1000000, 195000000, 0) / 10000000) *
            10000000;
        let ranBalance = Func.formatInteger(
            parseInt(Func.randomNumber(1000000, run, 0) / 10000000) * 10000000
        );
        return ranBalance ? ranBalance + " USD" : ranBalance;
    },
    randomBall() {
        let result = [];
        for (let i = 0; i < 3; i++) {
            result.push(Math.floor(Math.random() * 10));
        }
        return result;
    },
    randomDice() {
        let result = [];
        let items = Const.mGame.dice;
        for (let i = 0; i < 3; i++) {
            result.push(items[Math.floor(Math.random() * items.length)]);
        }
        return result;
    },
    filterChildren(children = null) {
        let f1 = [],
            f2 = [],
            f3 = [];
        if (children && children instanceof Array) {
            children.forEach((item) => {
                if (item.children) {
                    item.children.forEach((item1) => {
                        if (item1.children) {
                            item1.children.forEach((item2) => {
                                f3.push({
                                    _id: item2._id,
                                    username: item2.username,
                                    balance: Func.formatBalance(item2.balance),
                                });
                            });
                        }
                        f2.push({
                            _id: item1._id,
                            username: item1.username,
                            balance: Func.formatBalance(item1.balance),
                        });
                    });
                }
                f1.push({
                    _id: item._id,
                    username: item.username,
                    balance: Func.formatBalance(item.balance),
                });
            });
            return {
                f1: f1.length ? f1 : null,
                f2: f2.length ? f2 : null,
                f3: f3.length ? f3 : null,
            };
        }
        return null;
    },
    checkPermission(permission = null) {
        let permissions = Func.getStorage("permissions");
        permissions =
            permissions && permissions.length ? permissions.split(",") : null;
        if (permission && permissions) {
            if (
                permissions &&
                permissions instanceof Array &&
                permissions.includes(permission)
            ) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    },
    setUserStorage(data) {
        if (data) {
            Func.setStorage({
                token: data.hasOwnProperty("token")
                    ? data.token
                    : Func.getStorage("token"),
                token_expired: data.hasOwnProperty("token_expired")
                    ? data.token_expired
                    : Func.getStorage("token_expired"),
                username: data.hasOwnProperty("username")
                    ? data.username
                    : Func.getStorage("username"),
                id: data.hasOwnProperty("_id")
                    ? data._id
                    : Func.getStorage("_id"),
                name: data.hasOwnProperty("name")
                    ? data.name
                    : Func.getStorage("name"),
                image: data.hasOwnProperty("image")
                    ? data.image
                    : Func.getStorage("image"),
                code: data.hasOwnProperty("code")
                    ? data.code
                    : Func.getStorage("code"),
                invite_from_code: data.hasOwnProperty("invite_from_code")
                    ? data.invite_from_code
                    : Func.getStorage("invite_from_code"),
                balance: data.hasOwnProperty("balance")
                    ? data.balance
                    : Func.getStorage("balance"),
                bank_id: data.hasOwnProperty("bank_id")
                    ? data.bank_id
                    : Func.getStorage("bank_id"),
                bank_name: data.hasOwnProperty("bank_name")
                    ? data.bank_name
                    : Func.getStorage("bank_name"),
                bank_user: data.hasOwnProperty("bank_user")
                    ? data.bank_user
                    : Func.getStorage("bank_user"),
                bank_number: data.hasOwnProperty("bank_number")
                    ? data.bank_number
                    : Func.getStorage("bank_number"),
                email: data.hasOwnProperty("email")
                    ? data.email
                    : Func.getStorage("email"),
                phone: data.hasOwnProperty("phone")
                    ? data.phone
                    : Func.getStorage("phone"),
                address: data.hasOwnProperty("address")
                    ? data.address
                    : Func.getStorage("address"),
                info: data.hasOwnProperty("info")
                    ? data.info
                    : Func.getStorage("info"),
                gender: data.hasOwnProperty("gender")
                    ? data.gender
                    : Func.getStorage("gender"),
                birthday: data.hasOwnProperty("birthday")
                    ? data.birthday
                    : Func.getStorage("birthday"),
                vip: data.hasOwnProperty("vip")
                    ? data.vip
                    : Func.getStorage("vip"),
            });
            return true;
        }
        return false;
    },
    getAvatarDefault() {
        const avatars = [
            "/images/users/car (1).jpg",
            "/images/users/car (2).jpg",
            "/images/users/car (3).jpg",
            "/images/users/car (4).jpg",
            "/images/users/car (5).jpg",
            "/images/users/car (6).jpg",
            "/images/users/car (7).jpg",
            "/images/users/car (8).jpg",
            "/images/users/car (9).jpg",
            "/images/users/car (10).jpg",
            "/images/users/car (11).jpg",
            "/images/users/car (12).jpg",
            "/images/users/car (13).jpg",
            "/images/users/car (14).jpg",
            "/images/users/car (15).jpg",
            "/images/users/car (16).jpg",
            "/images/users/car (17).jpg",
            "/images/users/car (18).jpg",
            "/images/users/car (19).jpg",
            "/images/users/car (20).jpg",
            "/images/users/car (21).jpg",
            "/images/users/car (22).jpg",
            "/images/users/car (23).jpg",
            "/images/users/car (24).jpg",
            "/images/users/car (25).jpg",
            "/images/users/car (26).jpg",
            "/images/users/car (27).jpg",
            "/images/users/car (28).jpg",
            "/images/users/car (29).jpg",
            "/images/users/car (30).jpg",
            "/images/users/car (31).jpg",
            "/images/users/car (32).jpg",
            "/images/users/car (33).jpg",
            "/images/users/car (34).jpg",
            "/images/users/car (35).jpg",
            "/images/users/car (36).jpg",
            "/images/users/car (37).jpg",
            "/images/users/car (38).jpg",
            "/images/users/car (39).jpg",
            "/images/users/car (40).jpg",
            "/images/users/car (41).jpg",
            "/images/users/car (42).jpg",
            "/images/users/car (43).jpg",
            "/images/users/car (44).jpg",
            "/images/users/car (45).jpg",
            "/images/users/car (46).jpg",
            "/images/users/car (47).jpg",
            "/images/users/car (48).jpg",
            "/images/users/car (49).jpg",
            "/images/users/car (50).jpg",
            "/images/users/car (51).jpg",
            "/images/users/car (52).jpg",
            "/images/users/car (53).jpg",
            "/images/users/car (54).jpg",
            "/images/users/car (55).jpg",
            "/images/users/car (56).jpg",
            "/images/users/car (57).jpg",
            "/images/users/car (58).jpg",
            "/images/users/car (59).jpg",
            "/images/users/car (60).jpg",
            "/images/users/car (61).jpg",
            "/images/users/car (62).jpg",
            "/images/users/car (63).jpg",
            "/images/users/car (64).jpg",
            "/images/users/car (65).jpg",
            "/images/users/car (66).jpg",
            "/images/users/car (67).jpg",
            "/images/users/car (68).jpg",
        ];
        let avatar = Func.getStorage("avatar_default");
        if (avatar) {
            return avatar;
        } else {
            let avatar = avatars[Math.floor(Math.random() * avatars.length)];
            Func.setStorage({
                avatar_default: avatar,
            });
            return avatar;
        }
    },
};
export default Func;

export const GameStatus = ({ data, onComplete }) => {
    if (!data || !data.status || !data.status_text) {
        return <React.Fragment></React.Fragment>;
    }
    let status = parseInt(data.status);
    switch (status) {
        case 1:
            return (
                <div>
                    <Label
                        style={{ minWidth: "90px", textAlign: "center" }}
                        className="label__success"
                    >
                        {data.status_text}
                    </Label>
                </div>
            );
        case 2:
            return (
                <div>
                    <Label
                        style={{ minWidth: "90px", textAlign: "center" }}
                        className="label__warning"
                    >
                        {data.status_text}
                    </Label>
                </div>
            );
        case 3:
            return (
                <Label
                    style={{ minWidth: "90px", textAlign: "center" }}
                    className="label__danger"
                >
                    <Countdown
                        onComplete={onComplete}
                        date={new Date(data.end)}
                        renderer={({ hours, minutes, seconds, completed }) => {
                            return (
                                "Còn " +
                                zeroPad(hours) +
                                ":" +
                                zeroPad(minutes) +
                                ":" +
                                zeroPad(seconds)
                            );
                        }}
                    ></Countdown>
                </Label>
            );
        case 4:
            return (
                <div>
                    <Label
                        style={{ minWidth: "90px", textAlign: "center" }}
                        className="label__dark"
                    >
                        {data.status_text}
                    </Label>
                </div>
            );
        default:
            return <React.Fragment></React.Fragment>;
    }
};

export const RenderInferior = ({ children }) => {
    if (!children || !(children instanceof Array)) {
        return <React.Fragment></React.Fragment>;
    }
    children = Func.filterChildren(children);
    return (
        <React.Fragment>
            {children.f1 && (
                <div className="table__expand-column">
                    <div className="table__expand-item">
                        <strong style={{ fontWeight: "400" }}>
                            {children.f1.length} Cấp dưới trực tiếp (F1):
                        </strong>
                    </div>
                    <div className="table__expand-item">
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                            {children.f1.map((item, index) => {
                                return (
                                    <div
                                        key={item._id}
                                        className="bg__block"
                                        style={{
                                            marginRight: "3px",
                                            marginBottom: "3px",
                                            padding: "1px 5px",
                                            borderRadius: "3px",
                                        }}
                                    >
                                        <Text
                                            className="color__info"
                                            style={{ marginRight: "3px" }}
                                        >
                                            {item.username}
                                        </Text>
                                        <Text className="color__note">
                                            ({item.balance})
                                        </Text>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            )}
            {children.f2 && (
                <div className="table__expand-column">
                    <div className="table__expand-item">
                        <strong>{children.f2.length} Cấp dưới (F2):</strong>
                    </div>
                    <div className="table__expand-item">
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                            {children.f2.map((item, index) => {
                                return (
                                    <div
                                        key={item._id}
                                        className="bg__block"
                                        style={{
                                            marginRight: "3px",
                                            marginBottom: "3px",
                                            padding: "1px 5px",
                                            borderRadius: "3px",
                                        }}
                                    >
                                        <Text
                                            className="color__primary"
                                            style={{ marginRight: "3px" }}
                                        >
                                            {item.username}
                                        </Text>
                                        <Text className="color__note">
                                            ({item.balance})
                                        </Text>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            )}
            {children.f3 && (
                <div className="table__expand-column">
                    <div className="table__expand-item">
                        <strong>{children.f3.length} Cấp dưới (F3):</strong>
                    </div>
                    <div className="table__expand-item">
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                            {children.f3.map((item, index) => {
                                return (
                                    <div
                                        key={item._id}
                                        className="bg__block"
                                        style={{
                                            marginRight: "3px",
                                            marginBottom: "3px",
                                            padding: "1px 5px",
                                            borderRadius: "3px",
                                        }}
                                    >
                                        <Text
                                            className="color__warning"
                                            style={{ marginRight: "3px" }}
                                        >
                                            {item.username}
                                        </Text>
                                        <Text className="color__note">
                                            ({item.balance})
                                        </Text>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export const RenderTableExpandGameInfo = ({ data, items }) => {
    if (data && items && typeof items === "object") {
        return items.map((item) => {
            return (
                <div className="table__expand-item" key={data._id + item}>
                    <div>
                        <Text
                            className="color__main"
                            style={{ marginRight: "5px" }}
                        >
                            {data.bets_info[item].name}
                        </Text>
                        <span className="color__note">
                            ({data.bets_info[item].odds}x)
                        </span>
                        :
                    </div>
                    <div style={{ color: "green" }}>
                        {data.bets_info[item].count_bet} cược /{" "}
                        {data.bets_info[item].total_bet} USD
                    </div>
                </div>
            );
        });
    }
};

export const RenderBankInfo = ({ banksData, bankId, balance }) => {
    let bank =
        banksData && bankId
            ? banksData.filter((value) => value._id === bankId)
            : null;
    bank = bank && bank.length ? bank[0] : null;
    return bank &&
        balance &&
        !isNaN(parseFloat(balance)) &&
        parseFloat(balance) > 0 ? (
        <div className="bank-info">
            <div className="bank-info__title">Hướng dẫn thanh toán</div>
            <div className="bank-info__note">
                *Để tiến hành nạp tiền, quý khách hàng vui lòng chuyển khoản
                (C/K) số tiền <span>{Func.formatBalance(balance)}</span> tới
                kênh nạp tiền sau:
            </div>
            <div className="bank-info__item">
                <p>Ngân hàng:</p>
                <strong>{bank.bank_name}</strong>
                <Icon
                    type="far"
                    className="fa-copy"
                    onClick={(event) => Func.copy(bank.bank_name)}
                ></Icon>
            </div>
            <div className="bank-info__item">
                <p>Chủ tài khoản:</p>
                <strong>{bank.bank_user}</strong>
                <Icon
                    type="far"
                    className="fa-copy"
                    onClick={(event) => Func.copy(bank.bank_user)}
                ></Icon>
            </div>
            <div className="bank-info__item">
                <p>Số tài khoản:</p>
                <strong>{bank.bank_number}</strong>
                <Icon
                    type="far"
                    className="fa-copy"
                    onClick={(event) => Func.copy(bank.bank_number)}
                ></Icon>
            </div>
            <div className="bank-info__item">
                <p>Số tiền C/K:</p>
                <strong>{Func.formatBalance(balance)}</strong>
                <Icon
                    type="far"
                    className="fa-copy"
                    onClick={(event) => Func.copy(Func.formatBalance(balance))}
                ></Icon>
            </div>
            <div className="bank-info__item">
                <p>Nội dung C/K:</p>
                <strong>{Func.getStorage("username")}</strong>
                <Icon
                    type="far"
                    className="fa-copy"
                    onClick={(event) => Func.copy(Func.getStorage("username"))}
                ></Icon>
            </div>
            <div className="bank-info__note">
                {/* *Tỷ giá quy đổi theo tỷ giá thị trường. <br /> */}
                *Sau khi yêu cầu nạp được khởi tạo, tài vụ sẽ kiểm tra và nạp
                tiền vào tài khoản của quý khách. <br />
                *Chi tiết vui lòng liên hệ bộ phận hỗ trợ.
            </div>
        </div>
    ) : (
        <React.Fragment></React.Fragment>
    );
};
